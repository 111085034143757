import styled from '@emotion/styled';
import { useMemo } from 'react';
import { COLORS } from 'uniforms';

type LabelProps = {
    required?: boolean;
    label?: string;
    className?: string;
};

const Label = (props: LabelProps): JSX.Element => {
    const { label, className = '' } = props;
    return useMemo(
        () => (
            <LabelStyled className={`d-flex align-items-center label-container ${className}`}>
                {label && <span className="text-label">{label}</span>}
            </LabelStyled>
        ),
        [className, label],
    );
};

export default Label;

const LabelStyled = styled.div`
    .required-container {
        padding: 2px 6px;
        border-radius: 4px;
        background-color: ${COLORS.RED};
        color: ${COLORS.WHITE};
        font-size: 10px;
        margin-right: 6px;
    }
    .text-label {
        color: ${COLORS.PRIMARY};
    }
`;
