import { useMemo } from 'react';
import './YesOrNoBtn.scss';

type Props = {
    className?: string;
    value?: boolean;
    onYesSubmit: () => void;
    onNoSubmit: () => void;
    yesText?: string;
    noText?: string;
};

/**
 * Yes or No button
 * @param props
 * @returns
 */
const YesOrNoBtn = (props: Props): JSX.Element => {
    const { className = '', onYesSubmit, onNoSubmit, value = false, yesText = 'あり', noText = 'なし' } = props;
    return useMemo(
        () => (
            <div className={`d-flex align-items-center gap-2 ${className}`}>
                <div onClick={onYesSubmit} className={`yes-no-btn ${value ? 'active-badge' : 'inactive-badge'}`}>
                    {yesText}
                </div>
                <div onClick={onNoSubmit} className={`yes-no-btn ${!value ? 'active-badge' : 'inactive-badge'}`}>
                    {noText}
                </div>
            </div>
        ),
        [className, noText, onNoSubmit, onYesSubmit, value, yesText],
    );
};

export default YesOrNoBtn;
