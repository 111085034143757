import styled from '@emotion/styled';
import { Icon } from 'components';
import { ModalConfigItem } from 'models';
import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { COLORS } from 'uniforms';

/**
 * modal custom
 */
const ModalCustom = (props: ModalConfigItem): JSX.Element => {
    const { show, dismissible, isHide, children = <></>, BannerText = '', onClose, isHaveBanner = true } = props;

    return useMemo(
        () => (
            <ModalStyled show={show} onHide={isHide ? onClose : undefined}>
                <div>
                    {dismissible && (
                        <div className="header-modal-container">
                            {isHaveBanner ? <Icon.BannerModal /> : null}
                            <div className="banner-text">{BannerText}</div>
                        </div>
                    )}
                    {children}
                </div>
            </ModalStyled>
        ),
        [show, isHide, onClose, dismissible, isHaveBanner, BannerText, children],
    );
};

export default ModalCustom;

/**
 * modal styled
 */
const ModalStyled = styled(Modal)`
    .modal-dialog {
        display: flex;
        align-items: center;
        height: -webkit-fill-available;
        border-radius: 32px;
        .modal-content {
            border-radius: 32px;
            .header-modal-container {
                display: flex;
                justify-content: center;
                position: relative;
                .banner-text {
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    font-weight: 400;
                    font-size: 16px;
                    color: ${COLORS.PRIMARY};
                    width: 70%;
                    overflow-wrap: break-word;
                    text-align: center;
                }
            }
        }
        .padd-content {
            padding: 3rem;
        }
    }
`;
