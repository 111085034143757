import { FrameItem, Icon, Input } from 'components';
import { CoordinateModel } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import './SearchMapSuggestion.scss';

type Props = {
    onSelectLocation: (coordinate: CoordinateModel) => void;
    description?: string;
};

const SearchMapSuggestion = (props: Props): JSX.Element => {
    const { onSelectLocation, description } = props;
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {},
        debounce: 300,
    });
    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    /**
     * handle input location
     */
    const handleInput = useCallback(
        e => {
            setValue(e.target.value);
        },
        [setValue],
    );

    /**
     * handle clear value
     */
    const handleClearValue = useCallback(() => {
        setValue('');
        clearSuggestions();
    }, [setValue]);

    /**
     * handle select location in search box
     */
    const handleSelect = useCallback(
        ({ description }) =>
            (): void => {
                // When user selects a place, we can replace the keyword without request data from API
                // by setting the second parameter to "false"
                setValue(description, false);
                clearSuggestions();

                // Get latitude and longitude via utility functions
                getGeocode({ address: description }).then(results => {
                    const { lat, lng } = getLatLng(results[0]);
                    onSelectLocation({ coordinates: { lat: lat, lng: lng }, description });
                });
            },
        [clearSuggestions, onSelectLocation, setValue],
    );

    /**
     * render suggestion in search map box
     */
    const renderSuggestions = useCallback(
        () =>
            data.map(suggestion => {
                const { place_id, description } = suggestion;

                return (
                    <div className="suggestion-map" key={place_id} onClick={handleSelect(suggestion)}>
                        <div>
                            <Icon.Coordinate />
                        </div>
                        <span className="description">{description}</span>
                    </div>
                );
            }),
        [data, handleSelect],
    );

    useEffect(() => {
        if (description) {
            setValue(description);
        }
    }, [description]);

    return useMemo(
        () => (
            <FrameItem>
                <div className="search-map" ref={ref}>
                    <Input
                        mapInput={true}
                        placeholder="周辺スポットを探す"
                        value={value}
                        onChange={handleInput}
                        disabled={!ready}
                        clearInput={!!value}
                        onClearValue={handleClearValue}
                    />
                    {status === 'OK' && <>{renderSuggestions()}</>}
                </div>
            </FrameItem>
        ),
        [handleClearValue, handleInput, ready, ref, renderSuggestions, status, value],
    );
};

export default SearchMapSuggestion;
