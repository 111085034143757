import styled from '@emotion/styled';
import { FrameItem, Icon } from 'components';
import { useCommon } from 'hooks';
import { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ALERT_STATUS, COLORS } from 'uniforms';
import './AlertModal.scss';

/**
 * header
 * @returns
 */
const AlertModal = (): JSX.Element => {
    const { alert, hideAlert } = useCommon();

    return useMemo(
        () => (
            <ModalStyled show={alert.isShow} onHide={hideAlert}>
                <FrameItem>
                    <div className="message-container">
                        {alert.status === ALERT_STATUS.SUCCESS && <Icon.SuccessStatus />}
                        {alert.status === ALERT_STATUS.WARNING && <Icon.WarningStatus />}
                        {alert.status === ALERT_STATUS.ERROR && <Icon.ErrorStatus />}
                        <div className="message-status">{alert.message}</div>
                        <Button className="green-btn " onClick={hideAlert}>
                            閉じる
                        </Button>
                    </div>
                </FrameItem>
            </ModalStyled>
        ),
        [alert.isShow, alert.message, alert.status, hideAlert],
    );
};

export default AlertModal;

/**
 * modal styled
 */
const ModalStyled = styled(Modal)`
    .modal-dialog {
        display: flex;
        align-items: center;
        height: -webkit-fill-available;
        .modal-content {
            border-radius: 32px;
            .message-container {
                padding: 32px 54px 24px 54px;
                text-align: center;
                .message-status {
                    font-weight: 500;
                    line-height: 26px;
                    font-size: 18px;
                    white-space: pre-wrap;
                    color: ${COLORS.BLACK};
                    margin-bottom: 24px;
                    margin-top: 24px;
                }
            }
        }
        .padd-content {
            padding: 3rem;
        }
    }
`;
