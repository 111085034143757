import { Button } from 'react-bootstrap';
import './ConfirmModal.scss';

type Props = {
    title?: string;
    isDeleteMode?: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    closeText?: string;
    submitText?: string;
};

const ConfirmModal = (props: Props): JSX.Element => {
    const {
        title = '',
        closeText = '閉じる',
        submitText = '削除する',
        onClose,
        onSubmit,
        isDeleteMode = false,
    } = props;
    return (
        <div className="confirm-modal-container p-3">
            <div className="title">{title}</div>
            <div className="d-flex gap-2 justify-content-center">
                {onClose && (
                    <Button className="cancel-btn" onClick={onClose}>
                        {closeText}
                    </Button>
                )}
                {onSubmit && (
                    <Button className={!isDeleteMode ? 'green-btn' : 'red-btn'} onClick={onSubmit}>
                        {submitText}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ConfirmModal;
