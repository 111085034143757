import { defaultPhoto } from 'assets/images';
import { CoordinateModel, SpotListItem } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SPOT_CATEGORY_TYPE, SPOT_CLEAR_TYPE, SPOT_LIST_TABS } from 'uniforms';
import './SpotItem.scss';

type Props = {
    viewType: string;
    spotItem: SpotListItem;
    onGoToDetail: () => void;
    isMissionOrCoupon?: string;
};

/**
 * my spot item
 * @returns
 */
const SpotItem = (props: Props): JSX.Element => {
    const { spotItem, onGoToDetail, viewType, isMissionOrCoupon = SPOT_CATEGORY_TYPE.MISSION } = props;
    const [location, setLocation] = useState<CoordinateModel>();

    const comment = useMemo(() => {
        return spotItem?.spot_comment?.replaceAll('\\n', '\n');
    }, [spotItem]);

    const spotClearWay = useMemo(() => {
        let spotClearWay = '';
        if (spotItem) {
            Object.keys(SPOT_CLEAR_TYPE).map(key => {
                if (SPOT_CLEAR_TYPE[key].value === spotItem.spot_clear_way) {
                    spotClearWay = SPOT_CLEAR_TYPE[key].label;
                }
            });
        }

        return spotClearWay;
    }, [spotItem]);

    const isOpacity = useMemo(() => {
        if (isMissionOrCoupon === SPOT_CATEGORY_TYPE.COUPON) {
            return !spotItem.is_enabled;
        } else {
            return spotItem.spot_clear_status > 0;
        }
    }, [isMissionOrCoupon, spotItem.is_enabled, spotItem.spot_clear_status]);

    /**
     * handle get location
     */
    const handleGetLocation = useCallback(async (latitude, longitude) => {
        if (latitude && longitude) {
            // const res = await handleGetPlace(latitude, longitude);
            setLocation({ coordinates: { lat: latitude, lng: longitude }, description: '' });
        } else {
            setLocation({ coordinates: { lat: latitude, lng: longitude }, description: '' });
        }
    }, []);

    useEffect(() => {
        handleGetLocation(spotItem?.latitude, spotItem?.longitude);
    }, [spotItem?.latitude, spotItem?.longitude]);

    return useMemo(
        () =>
            spotItem && (
                <div
                    className={`spot-item`}
                    onClick={
                        (isMissionOrCoupon === SPOT_CATEGORY_TYPE.COUPON && spotItem.is_enabled) ||
                        isMissionOrCoupon === SPOT_CATEGORY_TYPE.MISSION
                            ? onGoToDetail
                            : undefined
                    }>
                    <div className={`d-flex gap-2 ${isOpacity ? 'spot-opacity' : ''}`}>
                        {viewType === SPOT_LIST_TABS.LIST && (
                            <div className="col-3 col-md-3">
                                <div
                                    className="spot-status"
                                    style={{ color: spotItem.spot_clear_status > 0 ? '#59BB2B' : '#FFD25D' }}>
                                    {spotItem.spot_clear_status === 2 ? (
                                        <span>
                                            {isMissionOrCoupon === SPOT_CATEGORY_TYPE.COUPON ? (
                                                'クリア済'
                                            ) : (
                                                <>
                                                    使用済 {'\n'}
                                                    <small>（再挑戦可）</small>
                                                </>
                                            )}
                                        </span>
                                    ) : spotItem.spot_clear_status === 1 ? (
                                        isMissionOrCoupon === SPOT_CATEGORY_TYPE.COUPON ? (
                                            '使用済'
                                        ) : (
                                            'クリア済'
                                        )
                                    ) : isMissionOrCoupon === SPOT_CATEGORY_TYPE.COUPON ? (
                                        '未使用'
                                    ) : (
                                        '未クリア'
                                    )}
                                </div>
                                <div className="text-center">
                                    <LazyLoadImage
                                        className="image-spot"
                                        src={spotItem.photo_url || defaultPhoto}
                                        alt=""
                                    />
                                </div>
                            </div>
                        )}
                        <div className="w-100">
                            <div className="mission-name">{spotItem.spot_title}</div>
                            {isMissionOrCoupon === SPOT_CATEGORY_TYPE.MISSION && (
                                <div className="d-flex justify-content-between align-items-end">
                                    <div className="d-flex gap-3">
                                        <div className="spot-type">{spotItem.spot_type_display_name}</div>
                                        <div className="spot-clear-way">{spotClearWay}</div>
                                    </div>
                                    <div className="spot-points">{spotItem.spot_points}pt</div>
                                </div>
                            )}
                            <div className={`mission-detail ${viewType === SPOT_LIST_TABS.MAP ? 'mt-4' : ''}`}>
                                {comment}
                            </div>
                        </div>
                    </div>
                    {viewType === SPOT_LIST_TABS.MAP && (
                        <div
                            className={`d-flex align-items-center gap-4 mt-4 ${
                                spotItem.spot_clear_status > 0 ? 'spot-opacity' : ''
                            }`}>
                            <LazyLoadImage
                                className="image-spot-map mt-2"
                                src={spotItem.photo_url || defaultPhoto}
                                alt=""
                            />
                            <div className="spot-status">
                                {spotItem.spot_clear_status === 2
                                    ? 'クリア済（再挑戦可）'
                                    : spotItem.spot_clear_status === 1
                                    ? 'クリア済'
                                    : '未クリア'}
                            </div>
                        </div>
                    )}
                </div>
            ),
        [spotItem, isMissionOrCoupon, onGoToDetail, isOpacity, viewType, spotClearWay, comment],
    );
};

export default SpotItem;
