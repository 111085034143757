/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'ap-northeast-1',
    // dev
    // Auth: {
    //     identityPoolId: 'ap-northeast-1:f208892a-92c9-46c0-9da5-59e90ed45bae',
    //     identityPoolRegion: 'ap-northeast-1',
    //     // REQUIRED - Amazon Cognito Region
    //     region: 'ap-northeast-1',

    //     // OPTIONAL - Amazon Cognito User Pool ID
    //     userPoolId: 'ap-northeast-1_otTofjxXJ',

    //     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //     userPoolWebClientId: '56i8hqtjv8eiau79g02k946os0',

    //     mandatorySignIn: false,

    //     // OPTIONAL - Hosted UI configuration
    //     oauth: {
    //         domain: 'dev-fruitpark-lae-users.auth.ap-northeast-1.amazoncognito.com',
    //         scope: ['profile', 'openid'],
    //         redirectSignIn: ['https://dmha9f5deyo82.cloudfront.net?cognito=true'],
    //         redirectSignOut: 'https://localhost',
    //         responseType: 'token',
    //     },
    // },
    // prod
    Auth: {
        identityPoolId: 'ap-northeast-1:f208892a-92c9-46c0-9da5-59e90ed45bae',
        identityPoolRegion: 'ap-northeast-1',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-northeast-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-northeast-1_EgCXHmsaq',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '1kd2823du7c50ks7b7a3ba7on6',

        mandatorySignIn: false,

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'prod-fruitpark-lae-users.auth.ap-northeast-1.amazoncognito.com',
            scope: ['profile', 'openid'],
            redirectSignIn: ['https://lae-f.com?cognito=true'],
            redirectSignOut: 'https://localhost',
            responseType: 'token',
        },
    },
};

export default awsmobile;
