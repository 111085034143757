import { Icon } from 'components';
import { BottleActionItem, SpotListItem } from 'models';
import { useMemo } from 'react';
import './InfoBoxCustom.scss';
import { formatDate } from 'utils';
import { FORMAT_DATE } from 'uniforms';

type Props = {
    onClose: () => void;
    data: BottleActionItem;
};

/**
 * Info box component
 * @param props
 * @returns
 */
const InfoBoxCustom = (props: Props): JSX.Element => {
    const { data, onClose } = props;

    const actionDatetime = useMemo(
        () => formatDate(data.action_datetime.substring(0, 19), FORMAT_DATE.YEAR_MONTH_DAY_HOUR_MINUTE),
        [data.action_datetime],
    );

    return useMemo(
        () => (
            <div className="info-box-container">
                <div className="info-box">
                    <div className="text">
                        {`${actionDatetime}\n${data.spot_title ? data.spot_title + 'で' : ''}ボトル${
                            data.action_type_id === 2 ? 'に' : 'を'
                        }${data.action_type_display_name}した`}
                    </div>
                    <div className="info-box-tail">
                        <Icon.InfoBoxTail />
                    </div>
                </div>
            </div>
        ),
        [actionDatetime, data.action_type_display_name, data.action_type_id, data.spot_title],
    );
};
export default InfoBoxCustom;
