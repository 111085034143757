import { Icon, Medal, Modal } from 'components';
import { UserTitleList } from 'models';
import { Button } from 'react-bootstrap';
import './CongratulationModal.scss';
interface DataPropType {
    newPoint: number;
    prevPoint: number;
    plusPoint: number;
    clearStatus?: boolean;
    prevRank: number;
    currentRank: number;
}
type Props = {
    isShow: boolean;
    toggleShow(): void;
    data?: DataPropType;
    userTitle?: UserTitleList[];
};

const CongratulationModal = (props: Props): JSX.Element => {
    const {
        isShow,
        data = {
            newPoint: 0,
            prevPoint: 0,
            plusPoint: 0,
            clearStatus: false,
            prevRank: 0,
            currentRank: 0,
        },
    } = props;

    return (
        <Modal
            show={isShow}
            children={
                <div className="congratulation-moda-content">
                    <div className="congratulation-title">CONGRATULATIONS</div>
                    <div className="congratulation-subtitle">Mission Complete</div>
                    <div className="congratulation-area">
                        <span className="ranking-text">ランキング</span>
                        <div className="ranking-grow d-flex justify-content-between align-items-center mt-1">
                            <div className="old-ranking d-flex justify-content-between align-items-center gap-2">
                                <Medal width={58} height={58} user_ranking={data?.prevRank} />
                            </div>
                            <Icon.Arrow />
                            <div className="new-ranking d-flex justify-content-between align-items-center gap-2">
                                <Medal width={58} height={58} user_ranking={data?.currentRank} />
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 mb-4">
                            <Icon.PlusReward />
                        </div>

                        <div className="point-text">獲得ポイント</div>
                        <div className="congratulation-point-grow pt-2 d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center gap-2">
                                <Icon.PointCount />
                                <span className="point-number">{data?.prevPoint}</span>
                            </div>
                            <Icon.Arrow />
                            <div className="added-point d-flex justify-content-between align-items-center gap-2">
                                <Icon.PointCount />
                                <div className="point-number d-flex flex-column">
                                    <span>{data?.newPoint || 0}</span>
                                </div>
                                <div className="plus-point">+{data?.plusPoint || 0}</div>
                            </div>
                        </div>

                        <div className="medal-rank">
                            <Medal fontNumber={37} width={146} height={146} user_ranking={data?.currentRank} />
                        </div>
                    </div>
                    <Button className="green-btn" onClick={() => props.toggleShow()}>
                        閉じる
                    </Button>
                </div>
            }></Modal>
    );
};

export default CongratulationModal;
