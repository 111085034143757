import { FrameItem } from 'components';
import { StageActionItem } from 'models';
import { useMemo } from 'react';
import { Environments } from 'configs';
import './StageActionDetailDescribe.scss';
interface StageActionDetailDescribeProps {
    data: StageActionItem;
}

const StageActionDetailDescribe = ({ data }: StageActionDetailDescribeProps): JSX.Element => {
    const des = useMemo(() => {
        return data?.action_comment?.replaceAll('\\n', '\n');
    }, [data]);
    return (
        <div className="mt-10">
            <FrameItem
                children={
                    <div className="actiondetail-describe d-flex flex-column align-items-center">
                        <div className="spotdetail-image-coverage">
                            <div className="actiondetail-image">
                                <img src={Environments.baseUrlWeb + data?.action_photo_url} alt={data?.action_title} />
                            </div>
                        </div>
                        <div className="title">{data?.action_title}</div>
                        <div className="p-4 pt-0 w-100">
                            <div className={`describe`}>
                                <div className="desc">
                                    {des?.split('\n').map(t => (
                                        <span key={t}>
                                            {t}
                                            <br />
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default StageActionDetailDescribe;
