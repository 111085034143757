/* eslint-disable max-len */
import { Interpolation, Theme } from '@emotion/react';
import { JSX } from '@emotion/react/jsx-runtime';
import { Icon } from 'components';
import { SelectOptionItem } from 'models';
import { FocusEvent, forwardRef, ReactNode, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import Select, { components, DropdownIndicatorProps, GroupBase, InputActionMeta } from 'react-select';
import { COLORS } from 'uniforms';
import './Select.scss';

/**
 * props model
 * 小道具モデル
 */
type Props = {
    prefix?: string;
    icon?: ReactNode;
    showIcon?: boolean;
    showIndicator?: boolean;
    labelText?: string;
    className?: string;
    placeholder?: string;
    onChange?: (newValue: any) => void;
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    name?: string;
    defaultValue?: SelectOptionItem;
    value?: SelectOptionItem;
    menuIsOpen?: boolean;
    disabled?: boolean;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    error?: boolean;
    msgError?: string;
    options?: SelectOptionItem[] | undefined;
    customStyles?: any;
    inputValue?: string;
};

/**
 * Dropdown Indicator
 * ドロップダウンインジケーター
 */
const DropdownIndicator = (
    props: JSX.IntrinsicAttributes & { css?: Interpolation<Theme> } & DropdownIndicatorProps<
            unknown,
            boolean,
            GroupBase<unknown>
        >,
): any => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon.SeeMoreDown />
        </components.DropdownIndicator>
    );
};

/**
 * select custom
 * カスタムを選択
 */
const SelectCustom = forwardRef((props: Props, ref): JSX.Element => {
    const {
        showIndicator = true,
        prefix = '',
        labelText = '',
        className = '',
        placeholder = '',
        showIcon = false,
        icon = <></>,
        onChange,
        onBlur,
        name,
        defaultValue,
        value,
        menuIsOpen,
        disabled,
        onMenuOpen,
        onMenuClose,
        error = false,
        msgError = '',
        onInputChange,
        options = [],
        inputValue,
        customStyles = {
            placeholder: (provided: any): any => ({
                ...provided,
                color: COLORS.NAME,
            }),
            indicatorsContainer: (provided: any): any => ({
                ...provided,
                display: !showIndicator ? 'none' : 'block',
                height: '100%',
                margin: 'auto',
            }),
            clearIndicator: (): any => {},
            noOptionsMessage: (base): void => ({ ...base }),
            singleValue: (provided: any): any => ({
                ...provided,
                paddingLeft: 5,
            }),
        },
    } = props;
    const currentRef = useRef<any>();

    /**
     * function use in select
     */
    useImperativeHandle(ref, () => ({
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        handleResetValue() {
            setTimeout(() => {
                currentRef?.current?.clearValue();
            }, 0);
        },
    }));

    /**
     * custom Style Prop
     * カスタムスタイルのプロップ
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const customStyleProp = {
        ...customStyles,
        indicatorSeparator: (provided: any): any => ({
            ...provided,
            display: 'none',
        }),
    };

    /**
     * No Options Message
     * オプションメッセージなし
     */
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="custom-css-class">選択肢がありません。</span>
            </components.NoOptionsMessage>
        );
    };

    const getOption = useCallback(() => {
        if (!options.length) return [];
        return options;
    }, [options]);

    return useMemo(
        () => (
            <div className="select-options">
                {labelText && <label>{labelText}</label>}
                <div className="group">
                    <div className={`${prefix ? 'd-flex' : ''}`}>
                        <Select
                            ref={currentRef}
                            name={name}
                            inputValue={inputValue}
                            placeholder={placeholder}
                            className={`${className} ${showIcon ? '' : ''}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            defaultValue={defaultValue}
                            value={value}
                            menuIsOpen={menuIsOpen}
                            isDisabled={disabled}
                            isSearchable={true}
                            onMenuOpen={onMenuOpen}
                            onMenuClose={onMenuClose}
                            options={getOption()}
                            styles={customStyleProp}
                            onInputChange={onInputChange}
                            components={{ DropdownIndicator, NoOptionsMessage }}
                        />
                        {showIcon ? <div className="icon-select">{icon}</div> : <></>}
                        {prefix && <span className="align-self-center ms-3">{prefix}</span>}
                    </div>
                    {error && msgError && <span className="text-error">{msgError}</span>}
                </div>
            </div>
        ),
        [
            labelText,
            prefix,
            name,
            inputValue,
            placeholder,
            className,
            showIcon,
            onChange,
            onBlur,
            defaultValue,
            value,
            menuIsOpen,
            disabled,
            onMenuOpen,
            onMenuClose,
            getOption,
            customStyleProp,
            onInputChange,
            icon,
            error,
            msgError,
        ],
    );
});

export default SelectCustom;
