/* eslint-disable */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setAlert } from 'store/common';

/**
 * useCommon hook
 */
const useCommon = () => {
    const dispatch = useDispatch();
    const alert = useSelector((state: RootState) => state.alert);

    /**
     * show alert
     */
    const showAlert = useCallback(
        (message: string, status: string) => {
            dispatch(setAlert({ message: message, status: status, isShow: true }));
        },
        [dispatch],
    );

    const hideAlert = useCallback(() => {
        dispatch(setAlert({ isShow: false }));
    }, []);

    const usePermission = () => {
        const handleCheckPermission = callBack => {
            Promise.all([
                new Promise((resolve, reject) => {
                    if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
                        navigator.geolocation.getCurrentPosition(
                            () => {
                                resolve('granted');
                            },
                            () => {
                                reject('denied');
                            },
                        );
                    }
                }),
                new Promise((resolve, reject) => {
                    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                        navigator.mediaDevices
                            .getUserMedia({ video: true })
                            .then(stream => {
                                resolve('granted');
                            })
                            .catch(error => {
                                reject('denied');
                            });
                    } else {
                        reject('denied');
                    }
                }),
                new Promise((resolve, reject) => {
                    //@ts-ignore
                    if (
                        typeof DeviceMotionEvent !== 'undefined' &&
                        //@ts-ignore
                        typeof DeviceMotionEvent.requestPermission === 'function'
                    ) {
                        //@ts-ignore
                        DeviceMotionEvent.requestPermission()
                            .then(response => {
                                if (response === 'granted') {
                                    resolve('granted');
                                } else {
                                    reject('denied');
                                }
                            })
                            .catch(() => {
                                reject('denied');
                            });
                    } else {
                        resolve('denied');
                    }
                }),
            ])
                .then(results => {
                    let check = results.includes('denied');
                    if (check) {
                        callBack(false);
                    } else {
                        callBack(true);
                    }
                })
                .catch(() => {
                    callBack(false);
                });
        };
        return {
            handleCheckPermission,
        };
    };

    return useMemo(
        () => ({
            showAlert,
            hideAlert,
            usePermission,
            alert,
        }),
        [alert, hideAlert, showAlert],
    );
};

export default useCommon;
