import { defaultPhoto, DefaultYoutube } from 'assets/images';
import { Icon } from 'components';
import { StageItem } from 'models';
import { useMemo } from 'react';
import './StageListItem.scss';
import { getExtensionImage } from 'utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
    item: StageItem;
};

/**
 * my spot item
 * @returns
 */
const StageListItem = (props: Props): JSX.Element => {
    const { item } = props;

    const renderImageOrThumbnail = useMemo(() => {
        const extension = getExtensionImage(item.stage_photo_url)?.toLocaleLowerCase();
        const EXTENSION_IMAGE = ['png', 'jpg', 'jpeg', 'gif'];
        if (EXTENSION_IMAGE.includes(extension)) {
            return (
                <LazyLoadImage
                    className="image-spot"
                    width={80}
                    height={80}
                    src={item.stage_photo_url ? item.stage_photo_url : defaultPhoto}
                    alt="photo_url"
                />
            );
        } else {
            return (
                <LazyLoadImage
                    className="iframe-video-small"
                    width={80}
                    height={80}
                    src={DefaultYoutube}
                    alt="photo_url"
                />
            );
        }
    }, [item.stage_photo_url]);

    return useMemo(
        () =>
            props && (
                <div className="stage-list-item-wrapper">
                    <div className="stage-item d-flex">
                        {renderImageOrThumbnail}
                        <div>
                            <div className="stage-title">{item.stage_title}</div>
                            <div className="stage-description">{item.stage_comment}</div>

                            <div className="d-flex mt-2">
                                <div className="count-container" style={{ marginRight: '5px' }}>
                                    <div className="text-icon">アクション数</div>
                                    <div className="text-icon">{item.number_of_actions}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        [item.number_of_actions, item.stage_comment, item.stage_title, props, renderImageOrThumbnail],
    );
};

export default StageListItem;
