import { Armonia3 } from 'assets/images';
import {
    Armoria,
    AvatarFrame,
    CongratulationModal,
    FailedModal,
    FrameItem,
    Icon,
    Medal,
    Select,
    TitleField,
} from 'components';
import { useCommon, useUser } from 'hooks';
import {
    SelectOptionItem,
    UserClearParams,
    UserDetailItem,
    UserRank,
    UserStatusTitleList,
    UserTitleList,
} from 'models';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { ALERT_STATUS, MESSAGES, POINT_API_TRIGGER, STATUS_API } from 'uniforms';
import { showErrorMessage } from 'utils';
import './OtherDetail.scss';

interface OtherDetailProps {
    userInfo?: UserDetailItem;
    userRanking?: UserRank;
    userStatusTitleList?: UserStatusTitleList;
    reloadData: () => void;
}

const sizeAvatarFrame = {
    layer1: 96,
    layer2: 91,
    avatar: 77,
};

/**
 * my spot detail page
 * @returns
 */
const OtherDetail = (props: OtherDetailProps): JSX.Element => {
    const { userInfo, userRanking, userStatusTitleList, reloadData } = props;
    const { postUserClear, getUserRank, postUserStatus, getUserStatus } = useUser();
    const { showAlert } = useCommon();
    const [currentTitle, setCurrentTitle] = useState<UserTitleList>();
    const [thisIsPerson, setThisIsPerson] = useState<SelectOptionItem>({ label: '', value: 0 });
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [isShowFailedModal, setShowFailedModal] = useState<boolean>(false);
    const [isShowCongratulationModal, setShowCongratulationModal] = useState<boolean>(false);
    const [newUserRanking, setNewUserRanking] = useState(userRanking);
    const [preUserRanking, setPreUserRanking] = useState(userRanking);
    const [userTitle, setUserTitle] = useState<UserTitleList[]>([]);

    const comment = useMemo(() => {
        return userInfo?.user_comment_content?.replaceAll('\\n', `\n`);
    }, [userInfo]);

    const handleSubmit = useCallback(() => {
        if (userInfo?.spot_clear_count !== undefined && userInfo?.spot_clear_count < 2) {
            showAlert(
                showErrorMessage(MESSAGES.USER_CLEAR_WARNING, userInfo?.user_display_name || ''),
                ALERT_STATUS.WARNING,
            );
        } else {
            const params: UserClearParams = {
                target_cognito_user_id: userInfo?.cognito_user_id || '',
                user_clear_user_name: thisIsPerson.label,
            };
            postUserClear({
                ...params,
                successCallback(data) {
                    let title: UserTitleList[] = [];
                    let listPre: UserTitleList[] = [];
                    getUserStatus({
                        successCallback(data) {
                            if (data?.result_records?.user_status_title_list?.length > 0) {
                                listPre = data?.result_records?.user_status_title_list[0]?.user_title_list;
                            }
                        },
                        failureCallback(data) {},
                    });
                    getUserRank({
                        successCallback(dataRanking) {
                            if (
                                dataRanking?.result_records?.user_rank_list &&
                                dataRanking?.result_records?.user_rank_list.length > 0
                            ) {
                                setPreUserRanking(dataRanking.result_records.user_rank_list[0]);
                            }
                        },
                    });
                    const param = data.result_records.user_clear_result
                        ? POINT_API_TRIGGER.USER_CLEAR_SUCCESS
                        : POINT_API_TRIGGER.USER_CLEAR_FAILURE;
                    postUserStatus({
                        point_api_trigger: param,
                        successCallback() {
                            getUserRank({
                                successCallback(data_2) {
                                    setNewUserRanking(data_2.result_records.user_rank_list[0]);
                                    if (data.result_records.user_clear_result) {
                                        setShowCongratulationModal(true);
                                    } else {
                                        setShowFailedModal(true);
                                    }
                                },
                            });
                            getUserStatus({
                                successCallback(data) {
                                    if (data?.result_records?.user_status_title_list?.length > 0) {
                                        const list: UserTitleList[] =
                                            data?.result_records?.user_status_title_list[0]?.user_title_list;

                                        if (listPre.length > 0) {
                                            if (list.length > 0 && list.length > listPre.length) {
                                                list.forEach(item => {
                                                    let idx = listPre.findIndex(x => x.title_id === item.title_id);
                                                    if (idx < 0) {
                                                        title.push(item);
                                                    }
                                                });
                                            }
                                        } else {
                                            title = [...list];
                                        }

                                        setUserTitle(title);
                                    }
                                },
                                failureCallback(data) {},
                            });
                        },
                    });
                },
                failureCallback(data) {},
            });
        }
    }, [
        userInfo?.spot_clear_count,
        userInfo?.user_display_name,
        userInfo?.cognito_user_id,
        showAlert,
        thisIsPerson.label,
        postUserClear,
        postUserStatus,
        getUserRank,
        getUserStatus,
    ]);

    /**
     * handle choose person
     */
    const handleChoosePerson = useCallback(e => {
        setIsDisable(false);
        setThisIsPerson(e);
    }, []);

    useEffect(() => {
        const title = userStatusTitleList?.user_title_list.find(title => title.is_current_title);
        title && setCurrentTitle(title);
    }, [userStatusTitleList]);

    const handleCloseModal = (): void => {
        setShowFailedModal(false);
        setShowCongratulationModal(false);
        reloadData();
    };

    return (
        <div className="other-detail-container">
            <div className="mx-2 mt-3">
                <FrameItem>
                    <div className="other-user-info-detail rounded p-2">
                        <div className="achievement d-flex justify-content-between align-items-center">
                            <div className="achievement-left d-flex gap-2">
                                <Medal user_ranking={userRanking?.user_ranking || 0} width={32} height={32} />
                                {userRanking?.user_ranking === undefined || userRanking?.user_ranking === 0 ? (
                                    <span>ランキング外</span>
                                ) : (
                                    <span>{userRanking?.user_ranking}位</span>
                                )}
                            </div>
                            <div className="achievement-right d-flex gap-3 align-items-center">
                                <div className="location-count d-flex gap-1 align-items-center">
                                    <Icon.Location width="24" height="24" />
                                    <span>{userInfo?.spot_post_count}</span>
                                </div>
                                <div className="point-count d-flex gap-1 align-items-center">
                                    <Icon.PointCount width="24" height="24" />
                                    <span>{userRanking?.point}</span>
                                </div>
                                <div className="like-count d-flex gap-1 align-items-center">
                                    <Icon.LikeCount width="24" height="24" />
                                    <span>{userInfo?.total_spot_like_count}</span>
                                </div>
                            </div>
                        </div>
                        <div className="user-info d-flex gap-3 mt-3">
                            <div className="user-avatar">
                                <AvatarFrame avatar={userInfo?.avatar_url} size={sizeAvatarFrame} />
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                                {userInfo?.permission_id !== 2 ? (
                                    <div
                                        className={
                                            'clear-status ' + (userInfo?.user_clear_status ? 'cleared' : 'not-clear')
                                        }>
                                        {userInfo?.user_clear_status ? 'クリア済' : '未クリア'}
                                    </div>
                                ) : null}
                                {userInfo?.permission_id !== 2 ? (
                                    <div className="user-name d-flex align-items-center gap-2 mt-2">
                                        {userInfo?.user_clear_status && userInfo?.permission_id !== 2
                                            ? userInfo?.user_name
                                            : '？？？？？'}
                                    </div>
                                ) : null}
                                <div className="user-nickname d-flex align-items-center gap-2">
                                    {userInfo?.user_display_name}
                                </div>
                            </div>
                        </div>
                    </div>
                </FrameItem>
            </div>
            <div className="mx-2 my-2">
                <FrameItem
                    children={
                        <div className="other-user-bio-component rounded p-2">
                            <TitleField icon={<Icon.Chat />} title="みんなへのコメント" />
                            <span>{comment}</span>
                        </div>
                    }
                />
            </div>
            <div className="mx-2 my-2">
                <FrameItem>
                    <div className="other-user-degree-component rounded p-2">
                        <div className="other-user-best-degree d-flex justify-content-between rounded px-2 py-3">
                            <div className="shougou_content d-flex gap-2 align-items-center">
                                <span>現在の称号</span>
                                <img src={Armonia3} alt="" />
                                <span className="armoria_text">{currentTitle?.title_display_name}</span>
                            </div>
                        </div>
                        <div className="all-shougou rounded p-2 mt-1">
                            <span>獲得した称号</span>
                            {userStatusTitleList?.user_title_list.map((title, index) => (
                                <div key={index} className="d-flex gap-2 mt-2">
                                    <img src={Armonia3} alt="" />
                                    <span className="armoria_text">{title.title_display_name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </FrameItem>
            </div>
            <div className="mx-2 my-2">
                <FrameItem>
                    <div className="spot-cleared-component rounded p-2">
                        <span>このひとのスポットクリア状況</span>
                        <div className="spot-count d-flex justify-content-center align-items-center gap-3 mt-3">
                            <div className="total-spot">{userInfo?.spot_post_count}件中</div>
                            <div className="spot-cleared">{userInfo?.spot_clear_count}件クリア！</div>
                        </div>
                    </div>
                </FrameItem>
            </div>
            {userInfo?.permission_id !== 2 ? (
                userInfo?.user_clear_status !== undefined && !userInfo?.user_clear_status ? (
                    <div className="mx-2 my-2">
                        <FrameItem>
                            <div className="guess-user-component rounded p-2">
                                <span>このひとだあれ？</span>
                                <Select
                                    className="select-name mt-3"
                                    options={userInfo?.user_clear_user_list?.map((item, index) => {
                                        return { label: item.user_clear_user_name, value: index + 1 };
                                    })}
                                    onChange={handleChoosePerson}
                                />
                                <Button disabled={isDisable} className="green-btn w-100 mt-3" onClick={handleSubmit}>
                                    このひとです！
                                </Button>
                            </div>
                        </FrameItem>
                    </div>
                ) : null
            ) : null}

            <FailedModal
                oldPoint={preUserRanking?.point}
                newPoint={newUserRanking?.point}
                isShow={isShowFailedModal}
                toggleShow={(): void => handleCloseModal()}
            />

            <CongratulationModal
                isShow={isShowCongratulationModal}
                data={{
                    newPoint: newUserRanking?.point || 0,
                    prevPoint: preUserRanking?.point || 0,
                    plusPoint: (newUserRanking?.point || 0) - (preUserRanking?.point || 0),
                    prevRank: preUserRanking?.user_ranking || 0,
                    currentRank: newUserRanking?.user_ranking || 0,
                }}
                toggleShow={(): void => handleCloseModal()}
                userTitle={userTitle}
            />
        </div>
    );
};

export default OtherDetail;
