import UserDetail from 'pages/User/Detail';
import { ENVIRONMENT_ID, PATH } from 'uniforms';
import {
    ErrorNotFoundPage,
    Start,
    Event,
    MySpotDetail,
    MySpotList,
    MySpotRegistration,
    SpotList,
    UserList,
    SpotDetail,
    SpotStory,
    StageActionList,
    StageActionDetail,
    StageStart,
    Terms,
    UserRegister,
    UserRank,
    PointsExchange,
    BottleDetail,
    BorrowBottle,
    BorrowUsage,
    HomePage1,
    Payment,
    StageRegistrationList,
    StageRegistrationDetail,
    StageRegistration,
} from './pages';
import { Environments } from 'configs';

/**
 * private routes
 */
const routes = [
    {
        path: PATH.HOME,
        component: Environments.envId === ENVIRONMENT_ID.YOSHINOKUMANO ? <StageActionList /> : <SpotList />,
    },
    {
        path: PATH.START,
        component: <Start />,
    },
    {
        path: PATH.EVENT,
        component: <Event />,
    },
    {
        path: PATH.MY_SPOT.LIST,
        component: <MySpotList />,
    },
    {
        path: PATH.MY_SPOT.REGISTRATION,
        component: <MySpotRegistration />,
    },
    {
        path: PATH.MY_SPOT.DETAIL,
        component: <MySpotDetail />,
    },
    {
        path: PATH.SPOT.DETAIL,
        component: <SpotDetail />,
    },
    {
        path: PATH.SPOT.LIST,
        component: <SpotList />,
    },
    {
        path: PATH.SPOT.STORY,
        component: <SpotStory />,
    },
    {
        path: PATH.USER.LIST,
        component: <UserList />,
    },
    {
        path: PATH.USER.DETAIL,
        component: <UserDetail />,
    },
    {
        path: PATH.USER.DETAIL_2,
        component: <UserDetail />,
    },
    {
        path: PATH.USER.DETAIL_3,
        component: <UserDetail />,
    },
    {
        path: PATH.USER.REGISTER,
        component: <UserRegister />,
    },
    {
        path: PATH.USER.RANK,
        component: <UserRank />,
    },
    {
        path: PATH.STAGE.STAGE_ACTION_LIST,
        component: <StageActionList />,
    },
    {
        path: PATH.STAGE.STAGE_ACTION_DETAIL,
        component: <StageActionDetail />,
    },
    {
        path: PATH.STAGE.STAGE_START,
        component: <StageStart />,
    },
    {
        path: PATH.TERMS,
        component: <Terms />,
    },
    {
        path: PATH.BOTTLE.DETAIL,
        component: <BottleDetail />,
    },
    {
        path: PATH.BOTTLE.BORROW,
        component: <BorrowBottle />,
    },
    {
        path: PATH.BOTTLE.USAGE,
        component: <BorrowUsage />,
    },
    {
        path: PATH.USER.POINTS_EXCHANGE,
        component: <PointsExchange />,
    },
    {
        path: PATH.HOME_1,
        component: <HomePage1 />,
    },
    {
        path: PATH.PAYMENT,
        component: <Payment />,
    },
    {
        path: PATH.STAGE.REGISTRATION.STAGE_LIST,
        component: <StageRegistrationList />,
    },
    {
        path: PATH.STAGE.REGISTRATION.STAGE_DETAIL,
        component: <StageRegistrationDetail />,
    },
    {
        path: PATH.STAGE.REGISTRATION.STAGE_REGISTRATION,
        component: <StageRegistration />,
    },
    {
        path: PATH.NOT_FOUND,
        component: <HomePage1 />,
    },
];

/**
 * public routes
 */
const publicRoutes = [
    {
        path: PATH.NOT_FOUND,
        component: <HomePage1 />,
    },
];

export { publicRoutes };
export default routes;
