import { Icon, Modal } from 'components';
import { Button } from 'react-bootstrap';
import './FailedModal.scss';

type Props = {
    isShow: boolean;
    toggleShow: () => void;
    oldPoint?: number;
    newPoint?: number;
};

const FailedModal = (props: Props): JSX.Element => {
    const { isShow, oldPoint = 0, newPoint = 0 } = props;
    return (
        <Modal
            show={isShow}
            children={
                <div className="failed-moda-content">
                    <div className="failed-title">FAILED</div>
                    <div className="failed-subtitle">Mission Failed</div>
                    <div className="failed-area">
                        <div className="failed-area-title">
                            間違っているようです！{'\n'}
                            もう一度チャレンジしてね！
                        </div>
                        <div className="point-text">獲得ポイント</div>
                        <div className="failed-point-grow pt-2 d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center gap-2">
                                <Icon.PointCount />
                                <span className="point-number">{oldPoint}</span>
                            </div>
                            <Icon.Arrow />
                            <div className="added-point d-flex justify-content-between align-items-center gap-2">
                                <Icon.PointCount />
                                <div className="point-number d-flex flex-column">
                                    <span>{newPoint}</span>
                                </div>
                                <div className="plus-point">
                                    {oldPoint !== newPoint ? `+${newPoint - oldPoint}` : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button className="green-btn" onClick={() => props.toggleShow()}>
                        閉じる
                    </Button>
                </div>
            }></Modal>
    );
};

export default FailedModal;
