import { Icon } from "components";


type Props = {
    armoria_level: number,
    size?: string,
};

/**
 * Armoria component
 * @returns
 */
const Armoria = (props: Props): JSX.Element => {
    const { armoria_level, size } = props;

    const renderArmoria = () => {
        switch (armoria_level) {
            case 1:
                return <Icon.Armoria1 width={size} height={size} />;
            case 2:
                return <Icon.Armoria2 width={size} height={size} />;
            case 3:
                return <Icon.Armoria3 width={size} height={size} />;
        };

    };

    return (
        <>
            {renderArmoria()}
        </>
    );
};
export default Armoria;
