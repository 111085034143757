import { UserRank } from 'models';
import { useMemo } from 'react';
import './RankingItem.scss';
import ICONS from 'components/Icon';
import { getProfileUser, toCardinal } from 'utils';
import { useNavigate } from 'react-router-dom';

type Props = {
    item: UserRank;
    rank: number;
};

/**
 * Ranking item
 * @returns
 */
const RankingItem = (props: Props): JSX.Element => {
    const { item, rank } = props;
    const cognito_user_id = getProfileUser()?.cognito_user_id || '';

    const isCurrentUser = useMemo(() => {
        return cognito_user_id === item.cognito_user_id;
    }, [cognito_user_id, item.cognito_user_id]);

    const rankingColor = useMemo(() => {
        switch (rank) {
            case 1:
                return {
                    backgroundColor: '#FFF8EC',
                    borderColor: isCurrentUser ? '#42BDBF' : '#FFDC8E',
                    boxShadow: isCurrentUser ? '2px 2px 12px 0px #0000001F' : 'none',
                };
            case 2:
                return {
                    backgroundColor: '#F7F7F7',
                    borderColor: isCurrentUser ? '#42BDBF' : '#E7E7E7',
                    boxShadow: isCurrentUser ? '2px 2px 12px 0px #0000001F' : 'none',
                };
            case 3:
                return {
                    backgroundColor: '#ECFEFE',
                    borderColor: isCurrentUser ? '#42BDBF' : '#C2FAFA',
                    boxShadow: isCurrentUser ? '2px 2px 12px 0px #0000001F' : 'none',
                };

            default:
                return {
                    backgroundColor: '#fff',
                    borderColor: isCurrentUser ? '#42BDBF' : 'transparent',
                    boxShadow: isCurrentUser ? '2px 2px 12px 0px #0000001F' : 'none',
                };
        }
    }, [isCurrentUser, rank]);

    const rankingIcon = useMemo(() => {
        switch (rank) {
            case 1:
                return <ICONS.FirstRanking />;
            case 2:
                return <ICONS.SecondRanking />;
            case 3:
                return <ICONS.ThirdRanking />;

            default:
                if (isCurrentUser) {
                    return <div className="ranking-order" style={{ color: '#fff', backgroundColor: '#42BDBF' }}></div>;
                }
                return <div className="ranking-order"></div>;
        }
    }, [isCurrentUser, rank]);

    const rankingPointColor = useMemo(() => {
        switch (rank) {
            case 1:
                return {
                    color: '#D97702',
                };
            case 2:
                return {
                    color: '#000',
                };
            case 3:
                return {
                    color: '#42BDBF',
                };

            default:
                return {
                    color: '#000',
                };
        }
    }, [rank]);
    return useMemo(
        () =>
            item && (
                <div className="ranking-item-wrapper" style={rankingColor}>
                    <div className="d-flex justify-content-between">
                        <div className="title-rank">
                            <div className="ranking-medal">
                                {rankingIcon}
                                <div
                                    className="ranking-number"
                                    style={{ color: isCurrentUser && rank > 3 ? '#fff' : '#000' }}>
                                    {toCardinal(rank)}
                                </div>
                            </div>
                            <div className="ranking-display-name">{item.ranking_display_name}</div>
                        </div>
                        <div className="ranking-point">
                            <div style={rankingPointColor}>{item.point}</div>
                            <div className="point-text">ポイント</div>
                        </div>
                    </div>
                </div>
            ),
        [isCurrentUser, item, rank, rankingColor, rankingIcon, rankingPointColor],
    );
};

export default RankingItem;
