import { FrameItem, Icon, Select } from 'components';
import { UserTitleList } from 'models';
import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import './ChangeDegreeModal.scss';

type Props = {
    listDegree?: UserTitleList[];
    currentTitle?: UserTitleList;
    toggleShow: () => void;
    handleChangeTitle: (newTitle) => void;
};

const ChangeDegreeModal = (props: Props): JSX.Element => {
    const { listDegree, currentTitle } = props;

    const [newTitle, setNewTitle] = useState<any>(currentTitle);

    const [isDisableBtn, setDisableBtn] = useState(true);

    /**
     * handle search and choose degree
     */
    const handleChooseDegree = useCallback(
        e => {
            setNewTitle(e);
            setDisableBtn(e.value === currentTitle?.title_id);
        },
        [newTitle],
    );

    /**
     * handle save degree choosed
     */
    const handleSave = () => {
        props.handleChangeTitle(newTitle);
    };

    return (
        <div className="change-degree-container p-3">
            <FrameItem>
                <div className="change-degree-wrapper p-2 rounded">
                    <Select
                        className="select-degree"
                        options={listDegree?.map(item => {
                            return { label: item.title_display_name, value: item.title_id };
                        })}
                        onChange={handleChooseDegree}
                        showIcon={true}
                        icon={<Icon.Armoria3 width="24" height="23" />}
                        defaultValue={{ label: newTitle?.title_display_name, value: newTitle?.title_id }}
                    />
                </div>
            </FrameItem>

            <Button disabled={isDisableBtn} className="green-btn w-100 mt-3" onClick={() => handleSave()}>
                称号を設定する
            </Button>
        </div>
    );
};

export default ChangeDegreeModal;
