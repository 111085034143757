import { Armonia3 } from 'assets/images';
import {
    Armoria,
    AvatarFrame,
    ChangeDegreeModal,
    ChangeNameModal,
    FrameHighlightTitle,
    FrameItem,
    Icon,
    Input,
    Medal,
    Modal,
    TitleField,
    UserRelated,
} from 'components';
import { Form, FormikProvider, useFormik } from 'formik';
import { useUser } from 'hooks';
import {
    UserDetailItem,
    UserDetailUpdateInfo,
    UserRank,
    UserRelatedInfo,
    UserStatusTitleList,
    UserTitleList,
} from 'models';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { getExtensionImage, handleResizeImage } from 'utils';
import './SelfDetail.scss';

interface SelfDetailProps {
    userInfo?: UserDetailItem;
    userRanking?: UserRank;
    userStatusTitleList?: UserStatusTitleList;
}
/**
 * my spot detail page
 * @returns
 */
const SelfDetail = (props: SelfDetailProps, ref): JSX.Element => {
    const { id } = useParams();
    const { userInfo, userRanking, userStatusTitleList } = props;
    const [isShowChangeDegrreModal, setShowChangeDegrreModal] = useState<boolean>(false);
    const [isShowChangeNameModal, setShowChangeNameModal] = useState<boolean>(false);
    const [isShowChangeDisplayNameModal, setShowChangeDisplayNameModal] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState();
    const [newAvatar, setNewAvatar] = useState<any>(userInfo?.avatar_url);
    const [preview, setPreview] = useState('');
    const [currentTitle, setCurrentTitle] = useState<UserTitleList>();
    const [userRelatedInfoList, setUserRelatedInfoList] = useState<UserRelatedInfo[]>();
    const { getUserRelated, postUserDetail } = useUser();
    const [isUpdateAvatar, setIsUpdateAvatar] = useState<boolean>(false);

    useEffect(() => {
        const title = userStatusTitleList?.user_title_list.find(title => title.is_current_title);
        title && setCurrentTitle(title);
    }, [userStatusTitleList]);

    useImperativeHandle(ref, () => ({
        handleSubmitForm(): void {
            return formik.handleSubmit();
        },
    }));

    const comment = useMemo(() => {
        return userInfo?.user_comment_content?.replaceAll('\\n', '\n');
    }, [userInfo]);

    const sizeAvatarFrame = {
        layer1: 96,
        layer2: 91,
        avatar: 77,
    };

    const formik = useFormik({
        initialValues: {
            avatar: userInfo?.avatar_url || '',
            user_name: userInfo?.user_name || '',
            user_display_name: userInfo?.user_display_name || '',
            user_comment_content: comment || '',
            permission_id: userInfo?.permission_id || '',
            current_user_title_id: currentTitle?.title_id || '',
        },
        enableReinitialize: true,
        onSubmit: values => handleSubmitForm(values),
    });

    /**
     * handle submit
     */
    const handleSubmitForm = (values): void => {
        const modifyValues = {
            ...values,
            avatar: newAvatar,
            user_comment_content: values.user_comment_content.replaceAll('\n', ' '),
        } as UserDetailUpdateInfo;
        postUserDetail({
            avatar_is_update: isUpdateAvatar,
            user_detail_list: [modifyValues],

            successCallback(data) {},
            failureCallback(data) {},
        });
    };

    /**
     * handle toggle modal
     */
    const toggleChangeDegreeModal = (): void => {
        setShowChangeDegrreModal(!isShowChangeDegrreModal);
    };

    /**
     * handle toggle change name modal
     */
    const toggleChangeNameModal = (): void => {
        setShowChangeNameModal(!isShowChangeNameModal);
    };

    const toggleChangeDisplayNameModal = (): void => {
        setShowChangeDisplayNameModal(!isShowChangeDisplayNameModal);
    };

    useEffect(() => {
        if (!selectedFile) {
            setPreview('');
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = async (e): Promise<void> => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        setSelectedFile(e.target.files[0]);

        const file = e.target.files[0];
        const extension = getExtensionImage(e.target.files[0].name).toLocaleUpperCase();
        const image = await handleResizeImage(file, extension);
        setNewAvatar(image);
        setIsUpdateAvatar(true);
    };

    useEffect(() => {
        handleSeeOtherPeopleTitle();
    }, []);

    const handleSeeOtherPeopleTitle = (): void => {
        getUserRelated({
            successCallback(data) {
                setUserRelatedInfoList(data.result_records.related_user_list);
            },
            failureCallback(data) {},
        });
    };

    const handleChangeName = (name): void => {
        formik.setFieldValue('user_name', name);

        formik.handleSubmit();
        toggleChangeNameModal();
    };

    const handleChangeDisplayName = (name): void => {
        formik.setFieldValue('user_display_name', name);

        formik.handleSubmit();
        toggleChangeDisplayNameModal();
    };

    const handleChangeTitle = (title): void => {
        const modifyParam = { title_id: title.value, title_display_name: title.label } as UserTitleList;
        setCurrentTitle(modifyParam);
        formik.setFieldValue('current_user_title_id', title.value);

        formik.handleSubmit();
        toggleChangeDegreeModal();
    };

    return useMemo(
        () => (
            <div className="my-3 mx-2">
                <FrameItem>
                    <div className="self-detail-container p-2">
                        <div className="self-user-info-detail rounded">
                            <div className="achievement-component d-flex justify-content-between align-items-center">
                                <div className="achievement-left d-flex gap-2">
                                    <Medal user_ranking={userRanking?.user_ranking || 0} width={32} height={32} />
                                    {userRanking?.user_ranking == undefined || userRanking?.user_ranking === 0 ? (
                                        <span>ランキング外</span>
                                    ) : (
                                        <span>{userRanking?.user_ranking}位</span>
                                    )}
                                </div>
                                <div className="achievement-right d-flex gap-3 align-items-center">
                                    <div className="location-count d-flex gap-1 align-items-center">
                                        <Icon.Location width="24" height="24" />
                                        <span>{userInfo?.spot_post_count}</span>
                                    </div>
                                    <div className="point-count d-flex gap-1 align-items-center">
                                        <Icon.PointCount width="24" height="24" />
                                        <span>{userRanking?.point}</span>
                                    </div>
                                    <div className="like-count d-flex gap-1 align-items-center">
                                        <Icon.LikeCount width="24" height="24" />
                                        <span>{userInfo?.total_spot_like_count}</span>
                                    </div>
                                </div>
                            </div>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <>
                                        <div className="user-info-component d-flex gap-3 my-3 mx-2">
                                            <div className="user-avatar">
                                                <AvatarFrame
                                                    avatar={preview || userInfo?.avatar_url}
                                                    size={sizeAvatarFrame}
                                                />
                                                <label htmlFor="file-image-upload" className="edit-avatar-icon">
                                                    <Icon.EditPencil width="22" height="22" />
                                                </label>
                                                <Input
                                                    type="file"
                                                    id="file-image-upload"
                                                    accept="image/gif,image/jpeg,image/png"
                                                    key="upload-image"
                                                    name="image-upload-btn"
                                                    className="d-none"
                                                    onChange={onSelectFile}
                                                />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                {userInfo?.permission_id !== 2 ? (
                                                    <div className="user-name d-flex align-items-center gap-2">
                                                        {formik.values.user_name}
                                                        <div
                                                            className="edit-name-icon"
                                                            onClick={(): void => toggleChangeNameModal()}>
                                                            <Icon.EditPencil width="22" height="22" />
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <div className="user-nickname d-flex align-items-center gap-2">
                                                    {formik.values.user_display_name}
                                                    <div
                                                        className="edit-nickname-icon"
                                                        onClick={(): void => toggleChangeDisplayNameModal()}>
                                                        <Icon.EditPencil width="22" height="22" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <FrameItem>
                                            <div className="bio-component rounded p-2">
                                                <TitleField icon={<Icon.Chat />} title="みんなへのコメント" />
                                                <div className="input-container">
                                                    {/* <Input
                                                        id="user_comment_content"
                                                        type="text"
                                                        name="user_comment_content"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.user_comment_content}
                                                    /> */}

                                                    <textarea
                                                        id="user_comment_content"
                                                        name="user_comment_content"
                                                        onChange={formik.handleChange}
                                                        rows={3}
                                                        style={{ resize: 'none' }}
                                                        value={formik.values.user_comment_content}
                                                    />
                                                </div>
                                            </div>
                                        </FrameItem>
                                    </>
                                </Form>
                            </FormikProvider>

                            <div className="my-2">
                                <FrameHighlightTitle>
                                    <div className="degree-component d-flex justify-content-between rounded p-2">
                                        <div className="degree_content d-flex gap-2 align-items-center">
                                            <span>現在の称号</span>
                                            <img src={Armonia3} alt="" />
                                            <span className="armoria_text">{currentTitle?.title_display_name}</span>
                                        </div>
                                        <div
                                            className="henkou_degree_btn d-flex justify-content-center align-items-center"
                                            onClick={() => toggleChangeDegreeModal()}>
                                            変更
                                        </div>
                                    </div>
                                </FrameHighlightTitle>
                            </div>

                            <FrameItem>
                                <div className="all-degree-component rounded p-2">
                                    <TitleField icon={<Icon.Achievement />} title="獲得した称号" />
                                    {userStatusTitleList?.user_title_list.map(title => (
                                        <div key={title.title_id} className="d-flex gap-2 mt-2">
                                            <img src={Armonia3} alt="" />
                                            <span className="armoria_text">{title.title_display_name}</span>
                                        </div>
                                    ))}
                                </div>
                            </FrameItem>
                        </div>
                    </div>
                </FrameItem>
                <div className="mt-3">
                    <FrameItem>
                        <TitleField icon={<Icon.Social />} title="みんなの持っている称号" className="ps-2 pt-2" />
                        <div className="hokanohito_no_degree d-flex flex-column align-items-center p-2 rounded">
                            {userRelatedInfoList?.map((userRelatedInfo, index) => (
                                <div key={index} className="mt-2 w-100">
                                    <UserRelated userInfo={userRelatedInfo} />
                                </div>
                            ))}
                            {userRelatedInfoList && userRelatedInfoList.length > 1 && (
                                <div className="d-flex justify-content-end w-100 mt-2">
                                    <span
                                        onClick={(): void => handleSeeOtherPeopleTitle()}
                                        className="see-more-other-people">
                                        他の人の称号を見る
                                    </span>
                                </div>
                            )}
                        </div>
                    </FrameItem>
                </div>

                <Modal
                    show={isShowChangeDegrreModal}
                    dismissible={true}
                    isHide={true}
                    BannerText="称号リストから選択できる"
                    onClose={(): void => toggleChangeDegreeModal()}
                    children={
                        <ChangeDegreeModal
                            listDegree={userStatusTitleList?.user_title_list}
                            currentTitle={currentTitle}
                            toggleShow={(): void => setShowChangeDegrreModal(!isShowChangeDegrreModal)}
                            handleChangeTitle={(newTitle): void => handleChangeTitle(newTitle)}
                        />
                    }
                />

                <Modal
                    show={isShowChangeNameModal}
                    dismissible={true}
                    isHide={true}
                    isHaveBanner={false}
                    onClose={(): void => toggleChangeNameModal()}
                    children={
                        <ChangeNameModal
                            name={formik.values.user_name || ''}
                            handleChangeName={(values): void => handleChangeName(values)}
                            toggleShow={(): void => setShowChangeNameModal(!isShowChangeNameModal)}
                        />
                    }
                />

                <Modal
                    show={isShowChangeDisplayNameModal}
                    dismissible={true}
                    isHide={true}
                    isHaveBanner={false}
                    onClose={(): void => toggleChangeDisplayNameModal()}
                    children={
                        <ChangeNameModal
                            name={formik.values.user_display_name || ''}
                            handleChangeName={(values): void => handleChangeDisplayName(values)}
                            toggleShow={(): void => setShowChangeDisplayNameModal(!isShowChangeDisplayNameModal)}
                        />
                    }
                />
            </div>
        ),
        [
            handleSubmitForm,
            id,
            isShowChangeDegrreModal,
            userInfo,
            userRanking,
            userStatusTitleList,
            isShowChangeDisplayNameModal,
        ],
    );
};

export default forwardRef(SelfDetail);
