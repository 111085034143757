import { Input } from 'components';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from 'react-bootstrap';
import './ChangeNameModal.scss';

type Props = {
    name: string;
    handleChangeName: (values) => void;
    toggleShow: () => void;
};

const ChangeNameModal = (props: Props): JSX.Element => {
    const handleSubmitName = (values): void => {
        props.handleChangeName(values.name);
    };

    const handleClose = (): void => {
        props.toggleShow();
    };

    const formik = useFormik({
        initialValues: {
            name: props.name || '',
        },
        enableReinitialize: true,
        onSubmit: values => handleSubmitName(values),
    });

    return (
        <div className="change-name-container p-3">
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <>
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />

                        <div className="d-flex justify-content-between align-items-center">
                            <Button className="btn-modify-width red-btn mt-3" onClick={(): void => handleClose()}>
                                キャンセル
                            </Button>

                            <Button className="btn-modify-width green-btn mt-3" onClick={() => formik.submitForm()}>
                                変更
                            </Button>
                        </div>
                    </>
                </Form>
            </FormikProvider>
        </div>
    );
};

export default ChangeNameModal;
