import { useSelector } from 'react-redux';
import { RootState } from 'store';
import './Loading.scss';

/**
 * loading
 * @returns
 */
const Loading = (): JSX.Element => {
    const loading = useSelector((state: RootState) => state.loading);
    return (
        <div id="loading" className={`ovelay-spiner ${loading > 0 ? 'show' : ''}`}>
            <div className="loader"></div>
        </div>
    );
};

export default Loading;
