import { useMemo } from 'react';
import './FrameCover.scss';

/**
 * frame cover item on screen
 * @returns
 */
const FrameCover = ({ children }): JSX.Element => {
    return useMemo(() => <div className="frame-cover-container">{children}</div>, [children]);
};

export default FrameCover;
