/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MySpotDetailParams, MySpotDetailRegistParams, MySpotDetailUpdateParams, MySpotListParams } from 'models';
import {
    getMySpotDetailService,
    getMySpotListService,
    postMySpotDetailService,
    putMySpotDetailService,
} from 'services';

/**
 * useMySpot hook
 * @returns
 */
export function useMySpot() {
    return {
        postMySpotDetail: (payload: MySpotDetailRegistParams): Promise<void> => postMySpotDetailService(payload),
        putMySpotDetail: (payload: MySpotDetailUpdateParams): Promise<void> => putMySpotDetailService(payload),
        getMySpotList: (payload: MySpotListParams): Promise<void> => getMySpotListService(payload),
        getMySpotDetail: (payload: MySpotDetailParams): Promise<void> => getMySpotDetailService(payload),
    };
}
