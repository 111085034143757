import { useMemo } from 'react';
import './FrameItem.scss';

/**
 * avatar frame
 * @returns
 */
const FrameItem = ({ children }): JSX.Element => {
    return useMemo(() => <div className="frame-container">{children}</div>, [children]);
};

export default FrameItem;
