import { FrameItem, Icon, Select, TitleField } from 'components';
import { CategoryListItem, FilterUserListModel, SelectOptionItem, SpotClearStatus } from 'models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CLEAR_STATUS } from 'uniforms';
import './FilterUserModal.scss';

type Props = {
    onSubmit: (filter: FilterUserListModel) => void;
    filter: FilterUserListModel;
    categoryList: CategoryListItem[];
    userNameList: SelectOptionItem[];
    onLoadMore: () => void;
};

const FilterUserModal = (props: Props): JSX.Element => {
    const { onSubmit, filter, categoryList, userNameList, onLoadMore } = props;
    const categoryRef = useRef<any>(null);
    const userNameRef = useRef<any>(null);
    const [currentCategory, setCurrentCategory] = useState<CategoryListItem[]>([]);
    const [clearStatus, setClearStatus] = useState<SpotClearStatus>({ uncleared: false, cleared: false });
    const [currentUserName, setCurrentUserName] = useState<SelectOptionItem>();
    const [userName, setUserName] = useState<SelectOptionItem[]>([]);

    /**
     * handle choose status filter uncleared or cleared
     */
    const handleChooseStatusFilter = useCallback(
        (status: string) => (): void => {
            if (status === CLEAR_STATUS.UNCLEARED) {
                setClearStatus({ uncleared: !clearStatus.uncleared, cleared: clearStatus.cleared });
            } else if (status === CLEAR_STATUS.CLEARED) {
                setClearStatus({ uncleared: clearStatus.uncleared, cleared: !clearStatus.cleared });
            }
        },
        [clearStatus],
    );

    /**
     * handle search and choose category
     */
    const handleChooseCategory = useCallback(
        e => {
            if (!currentCategory.find(item => item.id === e?.value)) {
                setCurrentCategory([...currentCategory, { id: e?.value, name: e?.label }]);
            }
        },
        [currentCategory],
    );

    /**
     * handle remove category
     */
    const handleRemoveCategory = useCallback(
        categoryLabel => (): void => {
            const newCategoryList = currentCategory.filter(item => item.id !== categoryLabel?.id);
            setCurrentCategory(newCategoryList);
        },
        [currentCategory],
    );

    const handleChooseUser = useCallback(e => {
        setCurrentUserName(e);
    }, []);

    /**
     * handle confirm button
     */
    const handleConfirm = useCallback(() => {
        let clear: number = 2;
        if (clearStatus.uncleared && !clearStatus.cleared) {
            clear = 0;
        } else if (!clearStatus.uncleared && clearStatus.cleared) {
            clear = 1;
        }

        let category: string[] = [];
        currentCategory.map(item => {
            category.push(item.name || '');
        });
        const filterList: FilterUserListModel = {
            user_clear_status: clear,
            spot_category_type_display_name: category,
            user_name: currentUserName?.label || '',
        };
        onSubmit && onSubmit(filterList);
    }, [clearStatus.cleared, clearStatus.uncleared, currentCategory, currentUserName?.label, onSubmit]);

    /**
     * handle reset
     */
    const handleReset = useCallback(() => {
        setCurrentCategory([]);
        setClearStatus({ uncleared: false, cleared: false });
        categoryRef?.current?.handleResetValue();
        userNameRef?.current?.handleResetValue();
    }, []);

    useEffect(() => {
        if (filter) {
            setClearStatus({ uncleared: filter.user_clear_status !== 1, cleared: filter.user_clear_status !== 0 });
        }
    }, [filter]);

    useEffect(() => {
        onLoadMore();
        let users: SelectOptionItem[] = [];
        userNameList.forEach(item => {
            if (!userName.find(data => data.label === item.label)) {
                users.push(item);
            }
        });

        setUserName(user => [...user, ...users]);
    }, [userNameList]);

    return useMemo(
        () => (
            <div className="filter-spot-modal-container">
                <div className="mb-4">
                    <FrameItem
                        children={
                            <div className="p-2">
                                <TitleField icon={<Icon.FilterSpot />} title="人当て状態" />
                                <div className="clear-status-container">
                                    {Object.keys(CLEAR_STATUS).map(key => {
                                        return (
                                            <div
                                                key={key}
                                                onClick={handleChooseStatusFilter(CLEAR_STATUS[key])}
                                                className={`clear-status-box ${
                                                    (CLEAR_STATUS[key] === CLEAR_STATUS.UNCLEARED &&
                                                        clearStatus.uncleared) ||
                                                    (CLEAR_STATUS[key] === CLEAR_STATUS.CLEARED && clearStatus.cleared)
                                                        ? 'active'
                                                        : ''
                                                }`}>
                                                <div className="clear-status-text">{CLEAR_STATUS[key]}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    />
                </div>

                <div className="mb-4">
                    <FrameItem
                        children={
                            <div className="p-2">
                                <TitleField icon={<Icon.Category />} title="よく使うカテゴリ" />
                                <div className="category-container">
                                    <Select
                                        ref={categoryRef}
                                        className="select-category"
                                        showIndicator={false}
                                        showIcon={true}
                                        icon={<Icon.MagnifyingGlass />}
                                        options={categoryList.map(item => {
                                            return { label: item.name || '', value: item.id || 0 };
                                        })}
                                        onChange={handleChooseCategory}
                                    />

                                    <div className="category-list">
                                        {currentCategory &&
                                            currentCategory.length > 0 &&
                                            currentCategory[0] &&
                                            currentCategory[0].id !== undefined &&
                                            currentCategory.map(item => {
                                                return (
                                                    <div className="category-box-container" key={item.id}>
                                                        <div
                                                            className="category-item"
                                                            onClick={handleRemoveCategory(item)}>
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>

                <div className="mb-4">
                    <FrameItem
                        children={
                            <div className="p-2">
                                <TitleField icon={<Icon.Person />} title="登録者" />
                                <div className="tag-registrant-container">
                                    <Select
                                        className="select-tag-registrant"
                                        ref={userNameRef}
                                        options={userName}
                                        onChange={handleChooseUser}
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>

                <div className="btn-container">
                    <Button className="red-btn w-50" onClick={handleReset}>
                        選択をリセット
                    </Button>
                    <Button className="green-btn w-50" onClick={handleConfirm}>
                        決定
                    </Button>
                </div>
            </div>
        ),
        [
            categoryList,
            handleChooseCategory,
            currentCategory,
            userName,
            handleChooseUser,
            handleReset,
            handleConfirm,
            handleChooseStatusFilter,
            clearStatus.uncleared,
            clearStatus.cleared,
            handleRemoveCategory,
        ],
    );
};

export default FilterUserModal;
