const MESSAGES = {
    EXPIRED_ID_TOKEN: 'セッションがタイムアウトしました。再度ログインしてください。',
    CHECK_REQUIRED: '{{field_name}}を入力してください。',
    CHOOSE_REQUIRED: '{{field_name}}を選択してください。',
    CHECK_VALID: '{{field_name}}が無効です。',
    USER_CLEAR_WARNING: '{{field_name}}さんが登録したスポットを２件以上クリアしてください！',
    COMMON_ERROR: 'エラーが発生しました。\nしばらく経った後にもう一度実施してください。',
};

export default MESSAGES;
