import { useJsApiLoader } from '@react-google-maps/api';
import { Amplify, Auth } from 'aws-amplify';
import { AlertModal, Layout, Loading } from 'components';
import { Environments } from 'configs';
import moment from 'moment';
import { Suspense, useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from 'routes';
import { FORMAT_DATE } from 'uniforms';
import KEY_STORAGE from 'uniforms/key-storage';
import Cookies from 'universal-cookie';
import { getCookie, getProfileUser } from 'utils';
import './App.css';
import awsExports from './aws-exports';
import liff from '@line/liff';
import { useUser } from 'hooks';
/**
 * App
 * @returns
 */
const App = (): JSX.Element => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: Environments.googleMapApiKey || '',
        libraries: ['places'],
    });
    const cookies = useMemo(() => new Cookies(), []);
    const { getUserDetail, postUserDetail } = useUser();
    const isAppInitLoad = window.location.href.includes('liff.state');
    const idToken = getCookie(KEY_STORAGE.ID_TOKEN);
    const appInit = async (): Promise<void> => {
        try {
            await liff.init({
                liffId: Environments.liffId,
            });
        } catch (e) {}
    };
    useEffect(() => {
        if (liff.getOS() !== 'web') {
            appInit();
        }
    }, []);

    useEffect(() => {
        if (!isAppInitLoad) {
            Amplify.configure(awsExports.Auth);
            const url = window.location.href;
            if (url.search('#')) {
                url.replace('#', '&');
            }
            const params = new URLSearchParams(url);
            const id_token = params.get(KEY_STORAGE.ID_TOKEN) ?? idToken;
            const expires_in = params.get(KEY_STORAGE.EXPIRES_IN);
            const expiredDate = moment().add(expires_in, 'seconds').format(FORMAT_DATE.FULL_DATE_TIME);
            if (id_token) {
                cookies.set(KEY_STORAGE.ID_TOKEN, id_token, { path: '/' });
                const urlRedirect = localStorage.getItem(KEY_STORAGE.URL_REDIRECT);

                if (urlRedirect) {
                    setTimeout(() => {
                        window.location.replace(urlRedirect);
                        localStorage.removeItem(KEY_STORAGE.URL_REDIRECT);
                    }, 1000);
                }
            } else {
                localStorage.setItem(KEY_STORAGE.URL_REDIRECT, url);
                Auth.federatedSignIn({ customProvider: 'LINE' });
            }

            if (expires_in) {
                localStorage.setItem(KEY_STORAGE.EXPIRES_IN, expiredDate);
            }
        }
    }, [cookies, idToken, isAppInitLoad]);

    useEffect(() => {
        liff.ready.then(async () => {
            try {
                const { friendFlag } = await liff.getFriendship();
                if (!friendFlag) {
                    window.location.replace(Environments.addFriendLink);
                }
            } catch (e) {}
        });
    }, []);

    useEffect(() => {
        let profile = getProfileUser();
        if (!profile) {
            getUserDetail({
                successCallback(data) {
                    if (data?.result_records && data?.result_records.length > 0) {
                        const profile = JSON.stringify(data?.result_records[0]);
                        localStorage.setItem(KEY_STORAGE.PROFILE, profile);
                    } else {
                        const params = {
                            avatar_is_update: false,
                        };

                        postUserDetail({
                            ...params,
                            successCallback(data) {
                                getUserDetail({
                                    successCallback(data) {
                                        if (data?.result_records && data?.result_records.length > 0) {
                                            const profile = JSON.stringify(data?.result_records[0]);
                                            localStorage.setItem(KEY_STORAGE.PROFILE, profile);
                                        }
                                    },
                                });
                            },
                        });
                    }
                },
            });
        }
    }, []);

    return useMemo(
        () => (
            <>
                <AlertModal />
                <Loading />
                {isLoaded ? (
                    <Suspense fallback={<Loading />}>
                        <Routes>
                            <Route element={<Layout />}>
                                {routes.map((item, key) => (
                                    <Route path={item.path} element={item.component} key={key} />
                                ))}
                            </Route>
                        </Routes>
                    </Suspense>
                ) : (
                    <Loading />
                )}
            </>
        ),
        [isLoaded],
    );
};

export default App;
