/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CategoryListParams } from 'models';
import { getCategoryListService } from 'services';

/**
 * useCategory hook
 * @returns
 */
export function useCategory() {
    return {
        getCategoryList: (payload: CategoryListParams): Promise<void> => getCategoryListService(payload),
    };
}
