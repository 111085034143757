/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    StageParams,
    StageActionDetailParams,
    StageActionClearParams,
    UserStageUnclearParams,
    StageDetailParams,
    StageRegistrationParams,
    StageUpdateParams,
} from 'models';
import {
    getStageService,
    postStageActionClearService,
    getStageActionDetailService,
    postUserStageUnclearService,
    getStageListService,
    postStageResetService,
    getStageDetailService,
    putStageDetailService,
    postStageDetailService,
} from 'services';

/**
 * useStage hook
 * @returns
 */
export function useStage() {
    return {
        getStage: (payload: StageParams): Promise<void> => getStageService(payload),
        getStageList: (payload: StageParams): Promise<void> => getStageListService(payload),
        getStageDetail: (payload: StageDetailParams): Promise<void> => getStageDetailService(payload),
        postStageDetail: (payload: StageRegistrationParams): Promise<void> => postStageDetailService(payload),
        putStageDetail: (payload: StageUpdateParams): Promise<void> => putStageDetailService(payload),
        // getStageActionDetail: (payload: StageActionDetailParams): Promise<void> => getStageActionDetailService(payload),
        postStageActionClear: (payload: StageActionClearParams): Promise<void> => postStageActionClearService(payload),
        postUserStageUnclear: (payload: UserStageUnclearParams): Promise<void> => postUserStageUnclearService(payload),
        postStageReset: (payload: StageParams): Promise<void> => postStageResetService(payload),
    };
}
