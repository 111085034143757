import { Icon, Label } from 'components';
import { ChangeEvent, FocusEvent, forwardRef, HTMLInputTypeAttribute, useCallback, useMemo } from 'react';
import './Input.scss';

/**
 * props model
 * 小道具モデル
 */
type InputProps = {
    label?: string;
    error?: boolean;
    msgError?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onClearValue?: () => void;
    type?: HTMLInputTypeAttribute;
    name?: string;
    value?: string | number | readonly string[];
    className?: string;
    placeholder?: string;
    required?: boolean;
    accept?: string;
    id?: string;
    suffixText?: string;
    disabled?: boolean;
    min?: number;
    max?: number;
    maxLength?: number;
    mapInput?: boolean;
    clearInput?: boolean;
    capture?: boolean;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    noControlled?: boolean;
    noControlledButton?: React.ReactNode;
};

/**
 * input component
 * 入力成分
 */
const Input = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref: any) => {
    const {
        error = false,
        msgError = '',
        label = '',
        onChange,
        onBlur,
        type = 'text',
        name,
        value,
        className = '',
        placeholder = '',
        required = false,
        accept = '',
        id,
        suffixText = '',
        disabled = false,
        min,
        max,
        maxLength,
        onFocus,
        mapInput,
        capture,
        clearInput = false,
        noControlled = false,
        noControlledButton = <></>,
        onClearValue,
    } = props;

    const onChangeValue = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            onChange && onChange(event);
        },
        [onChange],
    );

    return useMemo(
        () => (
            <div className={` d-flex flex-column ${className} input-custom`}>
                {label && <Label label={label} required={required} className="mb-1" />}

                <div className="input-container">
                    <input
                        placeholder={placeholder}
                        className={`bordered border-radius-4 ${error && 'input-error'} ${value && 'has-value'} ${
                            mapInput && 'map-input-value'
                        } ${clearInput && 'clear-input-value'}`}
                        ref={ref}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                            onChangeValue(e);
                        }}
                        capture={capture}
                        onBlur={onBlur}
                        name={name}
                        type={type}
                        {...(noControlled && { defaultValue: value })}
                        {...(!noControlled && { value: value })}
                        accept={accept}
                        id={id}
                        disabled={disabled}
                        min={min}
                        max={max}
                        maxLength={maxLength}
                        onFocus={onFocus}
                    />
                    {noControlledButton}

                    {suffixText.length > 0 && <div className="suffix-container">{suffixText}</div>}
                    {mapInput && (
                        <div className="map-input-container">
                            <Icon.Coordinate />
                        </div>
                    )}
                    {clearInput && (
                        <div className="clear-input-container" onClick={onClearValue}>
                            <Icon.ClearInput />
                        </div>
                    )}

                    {error && msgError && <span className={`text-error`}>{msgError}</span>}
                </div>
            </div>
        ),
        [
            className,
            label,
            required,
            placeholder,
            error,
            value,
            mapInput,
            clearInput,
            ref,
            capture,
            onBlur,
            name,
            type,
            noControlled,
            accept,
            id,
            disabled,
            min,
            max,
            maxLength,
            onFocus,
            noControlledButton,
            suffixText,
            onClearValue,
            msgError,
            onChangeValue,
        ],
    );
});

export default Input;

/**
 * Input default Props
 * 入力デフォルトの小道具
 * @returns void
 */
Input.defaultProps = {
    msgError: '',
    label: '',
    type: 'text',
    name: '',
    value: '',
    className: '',
    placeholder: '',
    accept: '',
    id: '',
    suffixText: '',
    error: false,
    required: false,
    disabled: false,
    min: 0,
    onChange: (): void => {},
    onBlur: (): void => {},
    onFocus: (): void => {},
};
