import { FrameItem } from 'components';
import { SpotListItem } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import './SpotDetailDescribe.scss';
interface SpotDetailDescribeProps {
    data: SpotListItem;
}

const OFFSET_SCROLL_HEIGHT = 30;

const SpotDetailDescribe = ({ data }: SpotDetailDescribeProps): JSX.Element => {
    const [scrolling, setScrolling] = useState(false);

    const des = useMemo(() => {
        return data?.spot_comment?.replaceAll('\\n', '\n');
    }, [data?.spot_comment]);

    const desAfterClear = useMemo(() => {
        return data?.spot_comment_after_clear && data?.spot_comment_after_clear.trim() !== ''
            ? data?.spot_comment_after_clear?.replaceAll('\\n', '\n')
            : data?.spot_comment?.replaceAll('\\n', '\n');
    }, [data?.spot_comment, data?.spot_comment_after_clear]);

    /*
    const handleScroll = useCallback(
        (event: any): void => {
            if ((data?.can_retry || (!data?.can_retry && data?.spot_clear_status === 0)) && data?.is_gps_required) {
                if (window.scrollY < OFFSET_SCROLL_HEIGHT && scrolling === true) {
                    setScrolling(false);
                } else if (window.scrollY >= OFFSET_SCROLL_HEIGHT && scrolling !== true) {
                    setScrolling(true);
                }
            }
        },
        [data?.can_retry, data?.is_gps_required, data?.spot_clear_status, scrolling],
    );
    */

    /* 
    useEffect(() => {
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll, { passive: true });
        return (): void => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);
    */

    return (
        <div>
            <FrameItem
                children={
                    <div className="spotdetail-describe d-flex flex-column align-items-center">
                        <div className="spotdetail-image-coverage">
                            <div className="spotdetail-image">
                                <img src={data?.photo_url} alt={data?.spot_title} />
                            </div>
                        </div>
                        <div className="title">{data?.spot_title}</div>
                        <div className="p-4 pt-0 w-100">
                            <div className={`describe ${scrolling ? 'hide-describe' : 'show-describe'}`}>
                                {des && data?.spot_clear_status === 0 && (
                                    <div className="desc">
                                        {des.split('\n').map(t => (
                                            <span key={t}>
                                                {t}
                                                <br />
                                            </span>
                                        ))}
                                    </div>
                                )}
                                {desAfterClear && data?.spot_clear_status > 0 && (
                                    <div className="desc">
                                        {desAfterClear.split('\n').map(t => (
                                            <span key={t}>
                                                {t}
                                                <br />
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default SpotDetailDescribe;
