import { Container } from 'react-bootstrap';
import { matchRoutes, Outlet, useLocation } from 'react-router-dom';
import routes, { publicRoutes } from 'routes';
import './Layout.scss';

/**
 * layout
 * @returns
 */
const Layout = (): JSX.Element => {
    const location = useLocation();
    const [{ route }]: any = matchRoutes([...routes, ...publicRoutes], location);

    return (
        <div className="body-container">
            <Container fluid className="p-0">
                <Outlet />
            </Container>
        </div>
    );
};

export default Layout;
