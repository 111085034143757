import { defaultPhoto } from 'assets/images';
import { MySpotListItem } from 'models';
import { useMemo } from 'react';
import './MySpotItem.scss';
import { ClearCountIcon, ViewCountIcon } from 'assets/icons';

type Props = {
    mySpotItem: MySpotListItem;
};

/**
 * my spot item
 * @returns
 */
const MySpotItem = (props: Props): JSX.Element => {
    const { mySpotItem } = props;
    return useMemo(
        () =>
            props && (
                <div className="my-spot-item-wrapper">
                    <div className="myspot-item d-flex">
                        <img
                            className="image-spot"
                            width={80}
                            height={80}
                            src={mySpotItem.photo_url ? mySpotItem.photo_url : defaultPhoto}
                            alt="photo_url"></img>
                        <div>
                            <div className="myspot-title">{mySpotItem.spot_title}</div>
                            <div className="d-flex" style={{ margin: '5px 0' }}>
                                <div className="spot-category">{mySpotItem.spot_type_display_name}</div>
                                <div className="spot-category border-left">{mySpotItem.spot_category_display_name}</div>
                            </div>

                            <div className="d-flex">
                                <div className="count-container" style={{ marginRight: '5px' }}>
                                    <img width={20} height={20} src={ViewCountIcon} alt="view-count-icon"></img>
                                    <div className="text-icon">{mySpotItem.spot_view_count}</div>
                                </div>

                                <div className="count-container">
                                    <img width={20} height={20} src={ClearCountIcon} alt="clear-count-icon"></img>
                                    <div className="text-icon">{mySpotItem.spot_clear_count}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        [mySpotItem, props],
    );
};

export default MySpotItem;
