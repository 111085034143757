/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    UserClearParams,
    UserDetailRegistParams,
    UserInfoParams,
    UserListParams,
    UserRankParams,
    UserStatusRegistParams,
    UserRichmenuParams,
    UserEventParams,
    GetUserEventParams,
    PointParams,
} from 'models';
import {
    getUserDetailService,
    getUserListService,
    getUserRankService,
    getUserRelatedService,
    getUserStatusService,
    postUserClearService,
    postUserDetailService,
    postUserStatusService,
    postUserRichmenuService,
    postUserEventService,
    getUserEventService,
    postPointService,
} from 'services';

/**
 * useUser hook
 * @returns
 */
export function useUser() {
    return {
        getUserDetail: (payload: UserInfoParams): Promise<void> => getUserDetailService(payload),
        postUserStatus: (payload: UserStatusRegistParams): Promise<void> => postUserStatusService(payload),
        postUserDetail: (payload: UserDetailRegistParams): Promise<void> => postUserDetailService(payload),
        getUserRank: (payload: UserRankParams): Promise<void> => getUserRankService(payload),
        getUserStatus: (payload: UserRankParams): Promise<void> => getUserStatusService(payload),
        getUserList: (payload: UserListParams): Promise<void> => getUserListService(payload),
        getUserRelated: (payload: UserInfoParams): Promise<void> => getUserRelatedService(payload),
        postUserClear: (payload: UserClearParams): Promise<void> => postUserClearService(payload),
        postUserRichmenu: (payload: UserRichmenuParams): Promise<void> => postUserRichmenuService(payload),
        postUserEvent: (payload: UserEventParams): Promise<void> => postUserEventService(payload),
        getUserEvent: (payload: GetUserEventParams): Promise<void> => getUserEventService(payload),
        postPoint: (payload: PointParams): Promise<void> => postPointService(payload),
    };
}
