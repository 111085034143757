/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    GetBottleMessageParams,
    GetBottleStatisticsAllParams,
    GetUserBottleStatisticsParams,
    PostBottleMessageParams,
    PostUserBottleParams,
    PutBottleMessageParams,
} from 'models';
import {
    getBottleMessageService,
    getBottleStatisticsAllService,
    getBottleStatisticsService,
    getUserBottleStatisticsService,
    postBottleMessageService,
    postUserBottleService,
    putBottleMessageService,
} from 'services';

/**
 * useUser hook
 * @returns
 */
export function useBottle() {
    return {
        getBottleStatistics: (payload: GetUserBottleStatisticsParams): Promise<void> =>
            getBottleStatisticsService(payload),
        getBottleStatisticsAll: (payload: GetBottleStatisticsAllParams): Promise<void> =>
            getBottleStatisticsAllService(payload),
        getUserBottleStatistics: (payload: GetBottleStatisticsAllParams): Promise<void> =>
            getUserBottleStatisticsService(payload),
        getBottleMessage: (payload: GetBottleMessageParams): Promise<void> => getBottleMessageService(payload),
        postBottleMessage: (payload: PostBottleMessageParams): Promise<void> => postBottleMessageService(payload),
        putBottleMessage: (payload: PutBottleMessageParams): Promise<void> => putBottleMessageService(payload),
        postUserBottle: (payload: PostUserBottleParams): Promise<void> => postUserBottleService(payload),
    };
}
