import { Header } from 'components';
import { useUser } from 'hooks/user';
import { UserDetailItem, UserRank, UserStatusTitleList } from 'models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PATH } from 'uniforms';
import { getProfileUser } from 'utils';
import OtherDetail from './component/OtherDetail';
import SelfDetail from './component/SelfDetail';
import './UserDetail.scss';

type UserDetailUpdateRef = {
    handleSubmitForm: () => void;
};

/**
 * my spot detail page
 * @returns
 */
const UserDetail = (): JSX.Element => {
    const ref = useRef<UserDetailUpdateRef>(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [userData, setUserData] = useState<UserDetailItem>();
    const { getUserDetail, getUserRank, getUserStatus } = useUser();
    const [userRanking, setUserRanking] = useState<UserRank>();
    const [userStatusTitleList, setUserStatusTitleList] = useState<UserStatusTitleList>();

    const USER_LOGGING_INFO = getProfileUser();
    /**
     * handle go back
     */
    const handleGoBack = useCallback(() => {
        // navigate(PATH.USER.LIST);
        navigate(-1);
    }, [navigate]);

    /**
     * handle submit
     */
    const handleSubmitForm = useCallback(() => {
        ref?.current?.handleSubmitForm();
    }, []);

    useEffect(() => {
        loadUserData();
    }, []);

    useEffect(() => {
        getUserRank({
            target_cognito_user_id: id === USER_LOGGING_INFO?.cognito_user_id ? undefined : id,
            successCallback(data) {
                setUserRanking(data.result_records.user_rank_list[0]);
            },
            failureCallback(data) {},
        });
    }, []);

    useEffect(() => {
        getUserStatus({
            target_cognito_user_id: id === USER_LOGGING_INFO?.cognito_user_id ? undefined : id,
            successCallback(data) {
                setUserStatusTitleList(data.result_records.user_status_title_list[0]);
            },
            failureCallback(data) {},
        });
    }, []);

    const loadUserData = (): void => {
        getUserDetail({
            target_cognito_user_id: id === USER_LOGGING_INFO?.cognito_user_id || id === ':id' || !id ? undefined : id,
            successCallback(data) {
                setUserData(data.result_records[0]);
            },
            failureCallback(data) {},
        });
    };

    return useMemo(
        () => (
            <div className="user-detail-container">
                <div className="detail-header">
                    <Header
                        backIcon={true}
                        saveIcon={USER_LOGGING_INFO?.cognito_user_id === id || id === ':id' || !id ? true : false}
                        textHeader={'参加者詳細 '}
                        onBack={handleGoBack}
                        onSubmit={handleSubmitForm}
                    />
                </div>
                {USER_LOGGING_INFO?.cognito_user_id === id || id === ':id' || !id ? (
                    <SelfDetail
                        ref={ref}
                        userInfo={userData}
                        userRanking={userRanking}
                        userStatusTitleList={userStatusTitleList}
                    />
                ) : (
                    <OtherDetail
                        userInfo={userData}
                        userRanking={userRanking}
                        userStatusTitleList={userStatusTitleList}
                        reloadData={(): void => loadUserData()}
                    />
                )}
            </div>
        ),
        [
            USER_LOGGING_INFO?.cognito_user_id,
            handleGoBack,
            handleSubmitForm,
            id,
            userData,
            userRanking,
            userStatusTitleList,
        ],
    );
};

export default UserDetail;
