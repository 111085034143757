import dayjs from 'dayjs';
import { OperationLogParams } from 'models';
import { postOperationLogService } from 'services';
import { FORMAT_DATE } from 'uniforms';

/**
 * useOperationLog hook
 * @returns
 */
export function useOperationLog() {
    return {
        postOperationLog: async (payload: OperationLogParams): Promise<void> => {
            let latitude = 0;
            let longitude = 0;
            try {
                let position: GeolocationPosition = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });

                latitude = position.coords.longitude;
                longitude = position.coords.latitude;
            } catch (error: any) {}
            let params: OperationLogParams = {
                ...payload,
                noLoading: true,
                latitude,
                longitude,
            };
            if (!params.operation_datetime) {
                params.operation_datetime = dayjs().format(FORMAT_DATE.YYYYMMDDHHmmss);
            }
            return postOperationLogService(params);
        },
    };
}
