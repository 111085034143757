import { FrameItem } from 'components';
import { CoordinateModel, LocationProps } from 'models';
import { useEffect, useState } from 'react';
import { handleGetPlace } from 'utils';
import './PointsExchangeMap.scss';
import MapCustom from './MapCustom';

type MapProps = {
    data: LocationProps[];
};

const PointsExchangeMap = ({ data }: MapProps): JSX.Element => {
    const [descriptions, setDescriptions] = useState<CoordinateModel[]>([]);

    const handleGetLocation = async (locations: LocationProps[]): Promise<void> => {
        const results = locations.map(async item => {
            const res: any = await handleGetPlace(item.latitude, item.longitude, false);
            return { coordinates: { lat: item.latitude, lng: item.longitude }, description: res.substring(3) };
        });
        const newDescriptions = await Promise.all(results);
        setDescriptions(newDescriptions);
    };

    useEffect(() => {
        handleGetLocation(data);
    }, [data]);

    return (
        <>
            <div className="location-exchange-title">景品交換場所</div>
            {/* {descriptions?.map((item, index) => (
                <div key={index} className="location-description">
                    {item?.description}
                </div>
            ))} */}
            <div className="location-description">
                ①西表島大原港ターミナル内　みなとのやおや{'\n'} ②石垣港離島ターミナル内　特設ブース
            </div>
            <FrameItem
                children={
                    <div className="points-exchange-location">
                        <div className="map">
                            <MapCustom data={data} />
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default PointsExchangeMap;
