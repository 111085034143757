import { useMemo } from 'react';
import { QrReader } from 'react-qr-reader';
import './QRCodeReader.scss';

type Props = {
    onScanQr?: (result: any) => void;
    bottleBorrow?: boolean;
};

const QRCodeReader = (props: Props): JSX.Element => {
    const { onScanQr, bottleBorrow = false } = props;
    return useMemo(
        () => (
            <div className="QR-code-reader-container">
                <QrReader
                    onResult={(result: any): void => {
                        if (!!result) {
                            const url = new URL(result?.text);
                            const params = new URLSearchParams(url.search);
                            let key = '';
                            if (bottleBorrow) {
                                key = params.get('b') ? params.get('b') || '' : params.get('bottle_id') || '';
                            } else {
                                key = params.get('c') ? params.get('c') || '' : params.get('spot_clear_key') || '';
                            }
                            onScanQr?.(key);
                        }
                    }}
                    constraints={{ facingMode: 'environment' }}
                />
            </div>
        ),
        [bottleBorrow, onScanQr],
    );
};

export default QRCodeReader;
