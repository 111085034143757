import { Icon } from 'components';
import { useMemo } from 'react';
import { toCardinal } from 'utils';

type Props = {
    user_ranking: number;
    fontNumber?: number;
    width?: number;
    height?: number;
};

/**
 * Armoria component
 * @returns
 */
const Medal = (props: Props): JSX.Element => {
    const { user_ranking = 10, width = 24, height = 24, fontNumber = 13 } = props;

    const renderMedal = useMemo(() => {
        switch (user_ranking) {
            case 1:
                return <Icon.FirstRanking width={width} height={height} />;
            case 2:
                return <Icon.SecondRanking width={width} height={height} />;
            case 3:
                return <Icon.ThirdRanking width={width} height={height} />;
            default:
                return (
                    <div
                        className="ranking-order"
                        style={{ width: `${width / 1.5}px`, height: `${height / 1.5}px` }}></div>
                );
        }
    }, [height, user_ranking, width]);

    return useMemo(
        () => (
            <div className="ranking-medal">
                {renderMedal}
                <div className="ranking-number" style={{ fontSize: fontNumber }}>
                    {toCardinal(user_ranking)}
                </div>
            </div>
        ),
        [fontNumber, renderMedal, user_ranking],
    );
};
export default Medal;
