import { fetcherApi } from 'api/fetcher';
import { PaymentParams } from 'models';
import { APILIST } from 'uniforms';
import { HandleCallbackApi } from 'utils';

export async function postPaymentService(params: PaymentParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<PaymentParams, any>(body, APILIST.PAYMENT.PAYMENT_CODE, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
