import { ReactNode, useMemo } from 'react';
import './TitleField.scss';

type Props = {
    icon?: ReactNode | boolean;
    title: string;
    titleNote?: string;
    className?: string;
};

/**
 * title field component
 * @param props
 * @returns
 */
const TitleField = (props: Props): JSX.Element => {
    const { icon, title = '', titleNote = '', className = '' } = props;
    return useMemo(
        () => (
            <div className={`d-flex justify-content-between align-items-center mb-12 ${className}`}>
                <div className="title-name">
                    {icon && <div className="icon-title"></div>}
                    <div>{title}</div>
                </div>
                <div className="title-note">{titleNote}</div>
            </div>
        ),
        [className, icon, title, titleNote],
    );
};

export default TitleField;
