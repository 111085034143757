import { Icon } from 'components';
import { BottleMessageItem } from 'models';
import { useMemo } from 'react';
import { FORMAT_DATE } from 'uniforms';
import { formatDate } from 'utils';
import './MessageBox.scss';

type Props = {
    messageItem: BottleMessageItem;
    enableDelete?: boolean;
    onDeleteComment?: (messageId: string) => void;
};

const MessageBox = (props: Props): JSX.Element => {
    const { messageItem, enableDelete = false, onDeleteComment } = props;

    const postDatetime = useMemo(
        () => formatDate(messageItem.post_datetime.substring(0, 19), FORMAT_DATE.YEAR_MONTH_DAY_HOUR_MINUTE),
        [messageItem.post_datetime],
    );
    return useMemo(
        () => (
            <div className="message-box-container">
                <div className="message-box" style={{ backgroundColor: enableDelete ? '#D6F4F4' : '#F8F4F4' }}>
                    <div className="tail-message-box">
                        <Icon.MessageBoxTail color={enableDelete ? '#D6F4F4' : '#F8F4F4'} />
                    </div>
                    <div className="comment-container">
                        <div className="comment">{messageItem.message_text}</div>
                        {enableDelete && (
                            <div
                                className="delete-comment"
                                onClick={(): void => {
                                    onDeleteComment && onDeleteComment(messageItem?.message_id);
                                }}>
                                {<Icon.Close />}
                            </div>
                        )}
                    </div>
                    <div className="datetime-user-message">
                        {postDatetime} | {messageItem.user_display_name}
                    </div>
                </div>
            </div>
        ),
        [
            enableDelete,
            onDeleteComment,
            messageItem?.message_id,
            messageItem.message_text,
            messageItem.user_display_name,
            postDatetime,
        ],
    );
};

export default MessageBox;
