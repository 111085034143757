import { fetcherApi } from 'api/fetcher';
import {
    GetBottleMessageParams,
    GetBottleStatisticsAllParams,
    GetUserBottleStatisticsParams,
    PostBottleMessageParams,
    PostUserBottleParams,
    PutBottleMessageParams,
} from 'models';
import { APILIST } from 'uniforms';
import { HandleCallbackApi } from 'utils';

export async function getBottleStatisticsService(params: GetUserBottleStatisticsParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<GetUserBottleStatisticsParams, any>(body, APILIST.BOTTLE.STATISTICS, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getBottleMessageService(params: GetBottleMessageParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<GetBottleMessageParams, any>(body, APILIST.BOTTLE.MESSAGE, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postBottleMessageService(params: PostBottleMessageParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const tempBody = { ...body };
    const response = await fetcherApi<PostBottleMessageParams, any>(tempBody, APILIST.BOTTLE.MESSAGE, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function putBottleMessageService(params: PutBottleMessageParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<PutBottleMessageParams, any>(body, APILIST.BOTTLE.MESSAGE, 'PUT');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getBottleStatisticsAllService(params: GetBottleStatisticsAllParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<GetBottleStatisticsAllParams, any>(body, APILIST.BOTTLE.ALL, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postUserBottleService(params: PostUserBottleParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<PostUserBottleParams, any>(body, APILIST.USER.BOTTLE, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getUserBottleStatisticsService(params: GetBottleStatisticsAllParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<GetBottleStatisticsAllParams, any>(body, APILIST.BOTTLE.USER_STATISTICS, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
