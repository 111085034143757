import { PaymentParams } from 'models';
import { postPaymentService } from 'services';

/**
 * usePayment hook
 * @returns
 */
export function usePayment() {
    return {
        postPayment: (payload: PaymentParams): Promise<void> => postPaymentService(payload),
    };
}
