import { fetcherApi } from 'api/fetcher';
import {
    PostSpotCommentParams,
    PostViewParams,
    ResponseResult,
    SpotCommentParams,
    SpotCommentResponse,
    SpotDetailParams,
    SpotDetailResponse,
    SpotLikeRegistParams,
    SpotListParams,
    SpotListResponse,
    DeleteCommentParams,
    PostSpotClearParams,
    PostSpotClearResponse,
    PostSpotUnclearParams,
} from 'models';
import { APILIST } from 'uniforms';
import { HandleCallbackApi } from 'utils';

export async function getSpotListService(params: SpotListParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<SpotListParams, SpotListResponse>(body, APILIST.SPOT.LIST, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getSpotCommentService(params: SpotCommentParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<SpotCommentParams, SpotCommentResponse>(body, APILIST.SPOT.COMMENT, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

// export async function getSpotDetailService(params: SpotDetailParams): Promise<void> {
//     const { successCallback, failureCallback, ...body } = params;
//     const response = await fetcherApi<MySpotDetailParams, MySpotDetailResponse>(body, APILIST.MYSPOT.DETAIL, 'GET');

//     HandleCallbackApi(successCallback, failureCallback, { ...response });
// }

export async function postSpotLikeService(params: SpotLikeRegistParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const tempBody = { ...body, noLoading: true };
    const response = await fetcherApi<SpotCommentParams, SpotCommentResponse>(tempBody, APILIST.SPOT.LIKE, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getSpotDetailService(params: SpotDetailParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<SpotDetailParams, SpotDetailResponse>(body, APILIST.SPOT.DETAIL, 'GET');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
export async function postSpotCommentService(params: PostSpotCommentParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const tempBody = { ...body, noLoading: true };
    const response = await fetcherApi<PostSpotCommentParams, ResponseResult>(tempBody, APILIST.SPOT.COMMENT, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
export async function postViewService(params: PostViewParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const tempBody = { ...body, noLoading: true };
    const response = await fetcherApi<PostViewParams, ResponseResult>(tempBody, APILIST.SPOT.VIEW, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
export async function deleteCommentService(params: DeleteCommentParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const tempBody = { ...body, noLoading: true };
    const response = await fetcherApi<DeleteCommentParams, ResponseResult>(tempBody, APILIST.SPOT.COMMENT, 'PUT');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
export async function postSpotClearService(params: PostSpotClearParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<PostSpotClearParams, PostSpotClearResponse>(body, APILIST.SPOT.CLEAR, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postSpotUnclearService(params: PostSpotUnclearParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<PostSpotUnclearParams, ResponseResult>(body, APILIST.SPOT.UNCLEAR, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

