import { lazy } from 'react';

const ErrorNotFoundPage = lazy(() => import('./Common/Error/NotFound'));
const HomePage = lazy(() => import('./Common/Home'));
const Start = lazy(() => import('./Common/Start'));
const Event = lazy(() => import('./Common/Event'));
const MySpotList = lazy(() => import('./MySpot/List'));
const MySpotRegistration = lazy(() => import('./MySpot/Registration'));
const MySpotDetail = lazy(() => import('./MySpot/Detail'));
const SpotDetail = lazy(() => import('./Spot/Detail'));
const SpotList = lazy(() => import('./Spot/List'));
const SpotStory = lazy(() => import('./Spot/Story'));
const UserList = lazy(() => import('./User/List'));
const UserRegister = lazy(() => import('./User/Register'));
const UserRank = lazy(() => import('./User/Rank'));
const StageActionList = lazy(() => import('./Stage/StageActionList'));
const StageActionDetail = lazy(() => import('./Stage/StageActionDetail'));
const StageStart = lazy(() => import('./Stage/StageStart'));
const Terms = lazy(() => import('./Terms'));
const PointsExchange = lazy(() => import('./User/PointsExchange'));
const BottleDetail = lazy(() => import('./Bottle/Detail'));
const BorrowBottle = lazy(() => import('./Bottle/Borrow'));
const Payment = lazy(() => import('./Payment'));
const BorrowUsage = lazy(() => import('./Bottle/Usage'));
const HomePage1 = lazy(() => import('./Home'));
const StageRegistrationList = lazy(() => import('./Stage/StageRegistration/StageList'));
const StageRegistration = lazy(() => import('./Stage/StageRegistration/StageRegistration'));
const StageRegistrationDetail = lazy(() => import('./Stage/StageRegistration/StageDetail'));

export {
    ErrorNotFoundPage,
    HomePage,
    MySpotList,
    MySpotRegistration,
    MySpotDetail,
    SpotList,
    UserList,
    SpotDetail,
    SpotStory,
    StageActionList,
    StageActionDetail,
    StageStart,
    Start,
    Event,
    Terms,
    UserRegister,
    UserRank,
    PointsExchange,
    BottleDetail,
    BorrowBottle,
    BorrowUsage,
    HomePage1,
    Payment,
    StageRegistrationList,
    StageRegistrationDetail,
    StageRegistration,
};
