import { fetcherApi } from 'api/fetcher';
import {
    UserClearParams,
    UserDetailRegistParams,
    UserInfoParams,
    UserInfoResponse,
    UserListParams,
    UserListResponse,
    UserRankParams,
    UserRankResponse,
    UserStatusRegistParams,
    UserRichmenuParams,
    UserEventParams,
    GetUserEventParams,
    PointParams,
} from 'models';
import { APILIST } from 'uniforms';
import { HandleCallbackApi } from 'utils';

export async function getUserDetailService(params: UserInfoParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserInfoParams, UserInfoResponse>(body, APILIST.USER.DETAIL, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postUserDetailService(params: UserDetailRegistParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserDetailRegistParams, any>(body, APILIST.USER.DETAIL, 'POST');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getUserRankService(params: UserRankParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserRankParams, UserRankResponse>(body, APILIST.USER.RANK, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getUserListService(params: UserListParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserListParams, UserListResponse>(body, APILIST.USER.LIST, 'POST');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
export async function postUserStatusService(params: UserStatusRegistParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserStatusRegistParams, any>(body, APILIST.USER.STATUS, 'POST');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getUserStatusService(params: UserRankParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const modifyBody = { noLoading: true, ...body };
    const response = await fetcherApi<UserRankParams, UserRankResponse>(modifyBody, APILIST.USER.STATUS, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getUserRelatedService(params: UserInfoParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserInfoParams, UserInfoResponse>(body, APILIST.USER.RELATION, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postUserClearService(params: UserClearParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserClearParams, any>(body, APILIST.USER.CLEAR, 'POST');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postUserRichmenuService(params: UserRichmenuParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserRichmenuParams, any>(body, APILIST.USER.RICHMENU, 'POST');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postUserEventService(params: UserEventParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserEventParams, any>(body, APILIST.USER.EVENT, 'POST');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getUserEventService(params: GetUserEventParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<UserEventParams, any>(body, APILIST.USER.EVENT, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postPointService(params: PointParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<PointParams, any>(body, APILIST.USER.POINT, 'POST');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
