import { Icon } from 'components';
import { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Header.scss';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'uniforms';

type Props = {
    backIcon?: boolean;
    saveIcon?: boolean;
    helpIcon?: boolean;
    homeIcon?: boolean;
    widthBanner?: string;
    heightBanner?: string;
    textHeader?: string;
    onBack?: () => void;
    onSubmit?: () => void;
    onHelp?: () => void;
};

/**
 * header
 * @returns
 */
const Header = (props: Props): JSX.Element => {
    const {
        backIcon = false,
        saveIcon = false,
        helpIcon = false,
        homeIcon = false,
        textHeader,
        onBack,
        onSubmit,
        onHelp,
    } = props;
    const navigate = useNavigate();

    /**
     * handle go back when click back icon
     */
    const handleGoBack = useCallback(() => {
        onBack && onBack();
    }, [onBack]);

    /**
     * handle go home when click home icon
     */
    const handleGoHome = useCallback(() => {
        navigate(PATH.HOME_1);
    }, [onBack]);

    /**
     * handle save when click save icon
     */
    const handleSave = useCallback(() => {
        onSubmit && onSubmit();
    }, [onSubmit]);

    /**
     * handle help when click help icon
     */
    const handleHelp = useCallback(() => {
        onHelp && onHelp();
    }, [onHelp]);

    return useMemo(
        () => (
            <div className="header-container">
                <Row>
                    <Col className="d-flex justify-content-start cursor-pointer" xs={2}>
                        {backIcon && (
                            <div onClick={handleGoBack}>
                                <Icon.BackIcon />
                            </div>
                        )}
                        {homeIcon && (
                            <div className="ms-2" onClick={handleGoHome}>
                                <Icon.Home width="25" height="25" />
                            </div>
                        )}
                    </Col>
                    <Col className="banner-header" xs={8}>
                        {textHeader}
                    </Col>
                    <Col className="d-flex justify-content-center cursor-pointer" xs={2}>
                        {saveIcon && (
                            <div onClick={handleSave}>
                                <Icon.SaveIcon />
                            </div>
                        )}
                        {helpIcon && (
                            <div onClick={handleHelp}>
                                <Icon.Help height="36" width="40" />
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        ),
        [backIcon, handleGoBack, handleGoHome, handleHelp, handleSave, helpIcon, homeIcon, saveIcon, textHeader],
    );
};

export default Header;
