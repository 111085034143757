/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    MySpotDetailParams,
    PostSpotCommentParams,
    PostViewParams,
    SpotCommentParams,
    SpotLikeRegistParams,
    SpotListParams,
    DeleteCommentParams,
    PostSpotClearParams,
    PostSpotUnclearParams
} from 'models';
import {
    getSpotCommentService,
    getSpotDetailService,
    getSpotListService,
    postSpotCommentService,
    postSpotLikeService,
    postViewService,
    deleteCommentService,
    postSpotClearService,
    postSpotUnclearService
} from 'services';

/**
 * useMySpot hook
 * @returns
 */
export function useSpot() {
    return {
        getSpotList: (payload: SpotListParams): Promise<void> => getSpotListService(payload),
        // getMySpotDetail: (payload: MySpotDetailParams): Promise<void> => getMySpotDetailService(payload),
        getSpotComment: (payload: SpotCommentParams): Promise<void> => getSpotCommentService(payload),
        postSpotLike: (payload: SpotLikeRegistParams): Promise<void> => postSpotLikeService(payload),
        getSpotDetail: (payload: MySpotDetailParams): Promise<void> => getSpotDetailService(payload),
        postSpotComment: (payload: PostSpotCommentParams): Promise<void> => postSpotCommentService(payload),
        postView: (payload: PostViewParams): Promise<void> => postViewService(payload),
        deleteComment: (payload: DeleteCommentParams): Promise<void> => deleteCommentService(payload),
        postSpotClear: (payload: PostSpotClearParams): Promise<void> => postSpotClearService(payload),
        postSpotUnclear: (payload: PostSpotUnclearParams): Promise<void> => postSpotUnclearService(payload),

    };
}
