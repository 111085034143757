import { fetcherApi } from 'api/fetcher';
import { CategoryListParams, CategoryListResponse } from 'models';
import { APILIST } from 'uniforms';
import { HandleCallbackApi } from 'utils';

export async function getCategoryListService(params: CategoryListParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<CategoryListParams, CategoryListResponse>(body, APILIST.CATEGORY, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
