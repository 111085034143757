/* eslint-disable */
import { MethodAPI, ResponseType } from 'models';
import { hideLoading } from 'utils';
import ApiServices from './api-services';

/**
 * fetcher Api
 * @param params
 * @param url
 * @param method
 * @returns
 */
export async function fetcherApi<T, S>(
    params: T & {
        noLoading?: boolean;
        noToken?: boolean;
    },
    url: string,
    method: MethodAPI,
): Promise<ResponseType<S> | undefined> {
    let customParams = { ...params };
    let res: ResponseType<S> | undefined = {
        error_message: '',
        error_exception: '',
        result_records: undefined,
        error_code: '0',
    };

    if (method === 'GET') {
        // Handle errors from the server
        try {
            res = await ApiServices.get<T, S>(url, customParams);
        } catch (error) {}
    } else if (method === 'POST') {
        // Handle errors from the server
        try {
            // alert(url);
            // alert(JSON.stringify(customParams));
            res = await ApiServices.post<T, S>(url, customParams);
            // alert(JSON.stringify(res));
        } catch (error) {}
    } else if (method === 'PUT') {
        try {
            res = await ApiServices.put<T, S>(url, customParams);
        } catch (error) {}
    }

    return res;
}
