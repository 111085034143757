/* eslint-disable max-len */
import React, { ChangeEvent, MouseEventHandler, useMemo } from 'react';
import './Radio.scss';

/**
 * props model
 * プロップスモデル
 */
type Props = {
    name?: string;
    value?: string | number | readonly string[];
    className?: string;
    label?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onClick?: MouseEventHandler<HTMLInputElement>;
    error?: boolean;
    msgError?: string;
    id: string;
    defaultValue?: string;
    checked?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
};

/**
 * radio
 * @param props
 * @returns
 */
const Radio = (props: Props): JSX.Element => {
    const {
        name,
        value,
        className = '',
        label = '',
        onChange,
        onClick,
        error = false,
        msgError = '',
        defaultValue,
        id,
        checked = false,
        disabled = false,
        readOnly = false,
    } = props;

    return useMemo(
        () => (
            <div className="d-flex flex-column">
                <div className={`d-flex align-items-end ${className}`}>
                    <input
                        className="form-check-input-radio"
                        type="radio"
                        defaultValue={defaultValue}
                        value={value}
                        name={name}
                        onChange={onChange}
                        id={id}
                        checked={checked}
                        disabled={disabled}
                        onClick={onClick}
                        readOnly={readOnly}
                    />
                    <label className="form-check-label-radio" htmlFor={id}>
                        {label}
                    </label>
                </div>
                {error && msgError && <span className="text-error">{msgError}</span>}
            </div>
        ),
        [
            className,
            defaultValue,
            value,
            name,
            onChange,
            id,
            checked,
            disabled,
            onClick,
            readOnly,
            label,
            error,
            msgError,
        ],
    );
};

export default Radio;
