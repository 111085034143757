/**
 * colors
 */
const Colors = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    PRIMARY: '#895E4D',
    BORDER: '#e59f21',
    RED: '#ff0000',
    BANNER_FILL: '#F2E7CA',
    TAIL_BANNER: '#A57562',
    STROKE_BANNER: '#91604C',
    NAME: '#000',
    TRANSPARENT: 'transparent',
};

export default Colors;
