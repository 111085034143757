import { Button } from 'react-bootstrap';
import './StageActionSelectModal.scss';

type Props = {
    actionId: number;
    title?: string;
    handleClose;
    handleSubmit;
};

const StageActionSelectModal = (props: Props): JSX.Element => {
    return (
        <div className="change-name-container p-3">
            <div className="title">{props.title}</div>
            <div className="d-flex justify-content-between align-items-center">
                <Button className="red-btn btn-modify-width mt-3" onClick={(): void => props.handleClose()}>
                    キャンセル
                </Button>

                <Button className="green-btn btn-modify-width mt-3" onClick={(): void => props.handleSubmit()}>
                    決定
                </Button>
            </div>
        </div>
    );
};

export default StageActionSelectModal;
