import { fetcherApi } from 'api/fetcher';
import { OperationLogParams } from 'models';
import { APILIST } from 'uniforms';
import { HandleCallbackApi } from 'utils';

export async function postOperationLogService(params: OperationLogParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<OperationLogParams, any>(body, APILIST.USER.OPERATION, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
