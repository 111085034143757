import { ConfirmModal, FrameCover, Icon, Input, Modal, Select, StageAction, TitleField, YesOrNoBtn } from 'components';
import { Field, FieldArray, Formik, FormikProps } from 'formik';
import { SelectOptionItem, StageActionItem, StageItem } from 'models';
import { ChangeEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ALERT_STATUS, MESSAGES } from 'uniforms';
import { getExtensionImage, getVideoIdFromYoutubeLink, handleResizeImage, showErrorMessage } from 'utils';
import * as Yup from 'yup';
import './StageRegistDetail.scss';
import StageRegistModal from './StageRegistModal';
import { defaultPhoto } from 'assets/images';
import { useCommon, useStage } from 'hooks';
import ICONS from 'components/Icon';

/**
 * Props type
 */
type Props = {
    id?: string;
    data?: StageItem;
    onSubmit: (values: StageItem) => void;
    onUpdateAction?: () => void;
    onDeleteStage?: () => void;
};

/**
 * stage registration detail component
 * @returns
 */
const StageRegistDetail = forwardRef((props: Props, ref): JSX.Element => {
    const { id, data, onSubmit, onUpdateAction, onDeleteStage } = props;
    const formikRef = useRef<FormikProps<StageItem>>(null);
    const { getStageList } = useStage();
    const { showAlert } = useCommon();
    const [imageSpot, setImageSpot] = useState<{ image: any }>({
        image: '',
    });
    const [isShowActionDetail, setIsShowActionDetail] = useState<boolean>(false);
    const [stageList, setStageList] = useState<StageItem[]>([]);
    const [currentStage, setCurrentStage] = useState<SelectOptionItem | null>(null);
    const [currentAction, setCurrentAction] = useState<StageActionItem>();
    const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
    const [isShowDeleteConfirmModal, setIsShowDeleteConfirmModal] = useState<boolean>(false);

    const StageOptions = useMemo(() => {
        if (id) {
            return stageList
                .filter(x => x.stage_id !== data?.stage_id)
                .map(item => {
                    return {
                        value: item.stage_id || 0,
                        label: item.stage_title,
                    };
                });
        }
        return stageList.map(item => {
            return {
                value: item.stage_id || 0,
                label: item.stage_title,
            };
        });
    }, [data?.stage_id, id, stageList]);

    const ActionOptions = useMemo(() => {
        if (!currentStage) {
            return [];
        }

        const temp = stageList.find(item => item.stage_id === currentStage.value);
        if (!temp) {
            return [];
        }

        if (temp.stage_action_items && temp.stage_action_items.length > 0) {
            return temp.stage_action_items.map(item => {
                return {
                    value: item.action_id || 0,
                    label: item.action_title,
                };
            });
        }

        return [];
    }, [currentStage, stageList]);

    const comment = useMemo(() => {
        return data?.stage_comment;
    }, [data]);

    const isPhotoOrYoutube = useMemo(() => {
        return (
            data?.stage_photo_url.includes('png') ||
            data?.stage_photo_url.includes('gif') ||
            data?.stage_photo_url.includes('jpg') ||
            data?.stage_photo_url.includes('jpeg')
        );
    }, [data?.stage_photo_url]);

    /**
     * initital value of form
     */
    const INITIAL_VALUES: StageItem = useMemo(() => {
        const commentUrlArr = data?.stage_comment_photo_url?.split(',');
        let commentUrl: string[] = [];
        if (commentUrlArr && commentUrlArr.length > 0) {
            commentUrl = commentUrlArr.map(item => {
                return item ? `https://www.youtube.com/embed/${item}` : '';
            });
        }
        return {
            ...data,
            stage_title: data?.stage_title || '',
            stage_comment: (comment || '').replaceAll('\\n', '\n'),
            is_first_stage: !!data?.is_first_stage,
            stage_photo_url: data?.stage_photo_url || '',
            comment_youtube_url: commentUrl,
            stage_comment_photo_url: commentUrl.length > 0 ? commentUrl.join(',') : '',
            is_photo_or_youtube: !!isPhotoOrYoutube,
            youtube_link:
                !isPhotoOrYoutube && data?.stage_photo_url
                    ? `https://www.youtube.com/embed/${data?.stage_photo_url}`
                    : '',
        };
    }, [data, comment, isPhotoOrYoutube]);

    /**
     * SCHEMA of Form
     */
    const SCHEMA = useMemo(
        () =>
            Yup.object().shape({
                stage_title: Yup.string().trim().required(showErrorMessage(MESSAGES.CHECK_REQUIRED, 'タイトル')),
                stage_comment: Yup.string()
                    .trim()
                    .required(showErrorMessage(MESSAGES.CHECK_REQUIRED, 'ミッションの説明')),
                stage_photo_url: Yup.string()
                    .trim()
                    .when('is_photo_or_youtube', {
                        is: true,
                        then: Yup.string().required(showErrorMessage(MESSAGES.CHOOSE_REQUIRED, '写真')),
                    }),
                youtube_link: Yup.string()
                    .trim()
                    .when('is_photo_or_youtube', {
                        is: false,
                        then: Yup.string().required(showErrorMessage(MESSAGES.CHOOSE_REQUIRED, 'Youtubeリンク')),
                    }),
            }),
        [],
    );

    /**
     * handle choose Yes or no
     */
    const handleChooseYesOrNoRequired = useCallback((key: string, item) => {
        formikRef?.current?.setFieldValue(key, item);
        switch (key) {
            default:
                break;
        }
    }, []);

    const handleCloseModal = useCallback(() => {
        setIsShowActionDetail(false);
        setIsShowDeleteModal(false);
        setIsShowDeleteConfirmModal(false);
        setCurrentAction(undefined);
    }, []);

    const handleAddStageAction = useCallback(() => {
        setIsShowActionDetail(true);
    }, []);

    const handleShowDeleteSpotModalConfirm = useCallback(() => {
        setIsShowDeleteConfirmModal(true);
    }, []);

    const handleShowDeleteSpotModal = useCallback(() => {
        setIsShowDeleteModal(true);
    }, []);

    const handleSubmitAction = useCallback(
        (value: StageActionItem) => {
            let currentActionArr = formikRef?.current?.values.stage_action_items || [];
            if (!value.action_id) {
                currentActionArr.push(value);
            } else {
                const index = currentActionArr.findIndex(item => item.action_id === value.action_id);
                currentActionArr[index] = value;
            }
            formikRef?.current?.setFieldValue('stage_action_items', currentActionArr);
            setIsShowActionDetail(false);
            onUpdateAction && onUpdateAction();
            setCurrentAction(undefined);
        },
        [onUpdateAction],
    );

    /**
     * handle submit form
     */
    const handleSubmitData = useCallback(
        (values: StageItem) => {
            if (
                (values.prev_stage_id as SelectOptionItem)?.value &&
                !(values.prev_action_id as SelectOptionItem)?.value
            ) {
                formikRef?.current?.setFieldError(
                    'prev_action_id',
                    showErrorMessage(MESSAGES.CHOOSE_REQUIRED, '前回のアクション'),
                );
                return;
            }

            let urlImage = values.is_photo_or_youtube
                ? values.stage_photo_url
                : getVideoIdFromYoutubeLink(values.youtube_link || '').videoId;

            const urlCommentUrlArr = values.comment_youtube_url;
            let stageCommentUrl: string[] = [];
            if (urlCommentUrlArr && urlCommentUrlArr.length > 0) {
                stageCommentUrl = urlCommentUrlArr
                    .filter(x => x)
                    .map(item => (item ? getVideoIdFromYoutubeLink(item).videoId : ''));
            }

            const params = {
                ...values,
                stage_photo_url: urlImage,
            };
            if (stageCommentUrl.length > 0) {
                params.stage_comment_photo_url = stageCommentUrl.join(',');
            }

            if (!values.stage_action_items || values.stage_action_items.length === 0) {
                delete params.stage_action_items;
            }

            delete params.youtube_link;
            delete params.comment_youtube_url;

            onSubmit(params);
        },
        [onSubmit],
    );

    /**
     * Handle upload image
     * @params e
     * @returns void
     */
    const handleUploadImage = useCallback(async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const extension = getExtensionImage(e.target.files[0].name).toLocaleUpperCase();
            const image = await handleResizeImage(file, extension);
            setImageSpot({ image });
            formikRef?.current?.setFieldValue('stage_photo_url', image);
        }
    }, []);

    /**
     * handle change previous stage
     */
    const handleChangeSelect = useCallback((value: any, key: string) => {
        formikRef?.current?.setFieldValue(key, value);
        if (key === 'prev_stage_id') {
            setCurrentStage(value);
            formikRef?.current?.setFieldValue('prev_action_id', { value: '', label: '' });
        }
    }, []);

    const handleDeleteAction = useCallback(
        (id: number) => {
            let currentActionArr = formikRef?.current?.values.stage_action_items || [];
            const index = currentActionArr.findIndex(item => item.action_id === id);
            currentActionArr.splice(index, 1);
            formikRef?.current?.setFieldValue('stage_action_items', currentActionArr);
            onUpdateAction && onUpdateAction();
        },
        [onUpdateAction],
    );

    const handleChangeArray = useCallback((e: any, index) => {
        const commentUrlArr = formikRef?.current?.values.comment_youtube_url;
        if (commentUrlArr && commentUrlArr.length > 0) {
            commentUrlArr[index] = e.target.value;
            formikRef?.current?.setFieldValue('comment_youtube_url', commentUrlArr);
        }
    }, []);

    useEffect(() => {
        if (data?.stage_photo_url) {
            setImageSpot({ image: isPhotoOrYoutube ? data.stage_photo_url : '' });
        }
    }, [data?.stage_photo_url, isPhotoOrYoutube]);

    useEffect(() => {
        getStageList({
            successCallback(data) {
                const stageList = data.result_records.stage_list;
                setStageList(stageList);
            },
            failureCallback(data) {
                showAlert(MESSAGES.COMMON_ERROR, ALERT_STATUS.ERROR);
            },
        });
    }, []);

    return useMemo(
        () => (
            <div className="stage-regist-detail-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={INITIAL_VALUES}
                    enableReinitialize={true}
                    validationSchema={SCHEMA}
                    onSubmit={handleSubmitData}>
                    {({ values, errors, handleSubmit, handleChange, handleBlur, touched }): JSX.Element => (
                        <form className="form-container" onSubmit={handleSubmit}>
                            <div className="form-submit-border">
                                <div className="mb-3">
                                    <FrameCover>
                                        <>
                                            {!id && (
                                                <>
                                                    <div className="mb-2">
                                                        <TitleField title="前のステージ" />
                                                        <Select
                                                            name="prev_stage_id"
                                                            options={StageOptions}
                                                            onChange={(value): void =>
                                                                handleChangeSelect(value, 'prev_stage_id')
                                                            }
                                                            value={values.prev_stage_id as SelectOptionItem}
                                                        />
                                                    </div>
                                                    <div className="mb-2">
                                                        <TitleField title="前回のアクション" />
                                                        <Select
                                                            name="prev_action_id"
                                                            options={ActionOptions}
                                                            onChange={(value): void =>
                                                                handleChangeSelect(value, 'prev_action_id')
                                                            }
                                                            value={values.prev_action_id as SelectOptionItem}
                                                            error={!!errors.prev_action_id && !!touched.prev_action_id}
                                                            msgError={errors.prev_action_id}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <div className="mb-2">
                                                <TitleField title="ステージ写真" />
                                                <div className="stage-category-list pb-3">
                                                    <YesOrNoBtn
                                                        value={values.is_photo_or_youtube}
                                                        yesText="写真"
                                                        noText="Youtube"
                                                        onYesSubmit={(): void => {
                                                            handleChooseYesOrNoRequired('is_photo_or_youtube', true);
                                                        }}
                                                        onNoSubmit={(): void => {
                                                            handleChooseYesOrNoRequired('is_photo_or_youtube', false);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {values.is_photo_or_youtube ? (
                                                <div className="stage-photo">
                                                    <div>
                                                        <label
                                                            htmlFor="file-image-upload"
                                                            className="custom-file-image-upload"
                                                            style={{
                                                                border: imageSpot.image && 'none',
                                                                padding: imageSpot.image && 0,
                                                            }}>
                                                            {imageSpot.image ? (
                                                                <img
                                                                    className="image-spot"
                                                                    height={160}
                                                                    width={160}
                                                                    src={imageSpot.image || defaultPhoto}
                                                                    alt="avatar"
                                                                />
                                                            ) : (
                                                                <>
                                                                    <Icon.Plus />
                                                                    <div className="mt-2 text-sm">写真 PHOTO</div>
                                                                </>
                                                            )}
                                                        </label>
                                                        <Input
                                                            type="file"
                                                            id="file-image-upload"
                                                            accept="image/gif,image/jpeg,image/png"
                                                            key="upload-image"
                                                            name="image-upload-btn"
                                                            className="d-none"
                                                            onChange={handleUploadImage}
                                                        />
                                                        {!!errors.stage_photo_url && !!touched.stage_photo_url && (
                                                            <div className="text-error">{errors.stage_photo_url}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="mb-2">
                                                    <TitleField title="Youtubeリンク" />
                                                    <Input
                                                        type="text"
                                                        name="youtube_link"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.youtube_link}
                                                        error={!!errors.youtube_link && !!touched.youtube_link}
                                                        msgError={errors.youtube_link}
                                                    />
                                                </div>
                                            )}
                                            <div className="mb-2">
                                                <TitleField title="タイトル" />
                                                <Input
                                                    type="text"
                                                    name="stage_title"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.stage_title}
                                                    error={!!errors.stage_title && !!touched.stage_title}
                                                    msgError={errors.stage_title}
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <TitleField title="説明" />
                                                <div className="input-container">
                                                    <textarea
                                                        className={`${errors.stage_comment ? 'input-error' : ''}`}
                                                        name="stage_comment"
                                                        rows={7}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.stage_comment}
                                                        style={{ resize: 'none' }}
                                                    />
                                                    {!!errors.stage_comment && !!touched.stage_comment && (
                                                        <div className="text-error">{errors.stage_comment}</div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mb-2">
                                                <TitleField title="Youtubeリンクコメント" />
                                                <FieldArray name="comment_youtube_url">
                                                    {({ remove, push }) => (
                                                        <div className="d-flex flex-column gap-3">
                                                            {values.comment_youtube_url &&
                                                                values.comment_youtube_url.length > 0 &&
                                                                values.comment_youtube_url.map((name, index) => (
                                                                    <div
                                                                        className="d-flex justify-content-between align-items-center"
                                                                        key={index}>
                                                                        <Input
                                                                            type="text"
                                                                            className="w-100 pe-4"
                                                                            name={`comment_${index}`}
                                                                            onChange={(e): void =>
                                                                                handleChangeArray(e, index)
                                                                            }
                                                                            onBlur={handleBlur}
                                                                            value={name}
                                                                        />
                                                                        <div onClick={(): void => remove(index)}>
                                                                            <Icon.Close />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            <Button
                                                                type="button"
                                                                className="green-btn"
                                                                onClick={(): void => push('')}>
                                                                コメントを追加
                                                            </Button>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </div>

                                            <div className="mb-2">
                                                <TitleField title="これは第一段階ですか？" />
                                                <div className="stage-category-list pb-3">
                                                    <YesOrNoBtn
                                                        value={values.is_first_stage}
                                                        onYesSubmit={(): void => {
                                                            handleChooseYesOrNoRequired('is_first_stage', true);
                                                        }}
                                                        onNoSubmit={(): void => {
                                                            handleChooseYesOrNoRequired('is_first_stage', false);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="d-flex w-100 my-4">
                                                <Button className="green-btn mx-auto" type="submit">
                                                    ステージを登録
                                                </Button>
                                            </div>
                                        </>
                                    </FrameCover>

                                    <FrameCover>
                                        <>
                                            <div className="d-flex w-100 my-4">
                                                <Button className="green-btn mx-auto" onClick={handleAddStageAction}>
                                                    アクションを追加
                                                </Button>
                                            </div>

                                            {values.stage_action_items && values.stage_action_items.length > 0 && (
                                                <div className="stage-action-list">
                                                    {values.stage_action_items.map(item => {
                                                        return (
                                                            <StageAction
                                                                key={item.action_id}
                                                                stageActionItem={item}
                                                                onGoToDetail={(): void => {
                                                                    setIsShowActionDetail(true);
                                                                    setCurrentAction(item);
                                                                }}
                                                                isShowDeleteIcon={true}
                                                                onDelete={(): void =>
                                                                    handleDeleteAction(item.action_id || 0)
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            )}

                                            <br />
                                            <br />

                                            <div className="text-error text-center whitespace-prewrap mb-12">
                                                他ユーザーのステージも削除可能です。{'\n'}
                                                取り扱いにはご注意ください。
                                            </div>

                                            <div className="d-flex justify-content-center w-100">
                                                <Button
                                                    disabled={!!!id}
                                                    onClick={handleShowDeleteSpotModal}
                                                    className={`${!!!id ? 'red-btn-disabled' : 'red-btn'}`}>
                                                    ステージを削除
                                                </Button>
                                            </div>
                                        </>
                                    </FrameCover>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>

                <Modal
                    show={isShowActionDetail}
                    dismissible={true}
                    isHide={true}
                    isHaveBanner={false}
                    onClose={handleCloseModal}
                    children={
                        <StageRegistModal
                            data={currentAction}
                            onClose={handleCloseModal}
                            stageOptions={StageOptions}
                            onSubmit={handleSubmitAction}
                        />
                    }
                />

                <Modal
                    show={isShowDeleteModal}
                    dismissible={true}
                    isHide={true}
                    isHaveBanner={false}
                    onClose={handleCloseModal}
                    children={
                        <ConfirmModal
                            title="ステージを削除します。"
                            onClose={handleCloseModal}
                            onSubmit={handleShowDeleteSpotModalConfirm}
                        />
                    }
                />
                <Modal
                    isHide={true}
                    show={isShowDeleteConfirmModal}
                    onClose={handleCloseModal}
                    children={
                        <ConfirmModal
                            title={'一度削除すると\nデータ復旧はできません。\n本当によろしいですか？'}
                            onClose={handleCloseModal}
                            onSubmit={(): void => onDeleteStage && onDeleteStage()}
                            closeText="いいえ"
                            submitText="はい"
                        />
                    }
                />
            </div>
        ),
        [
            INITIAL_VALUES,
            SCHEMA,
            handleSubmitData,
            isShowActionDetail,
            handleCloseModal,
            currentAction,
            StageOptions,
            handleSubmitAction,
            isShowDeleteModal,
            handleShowDeleteSpotModalConfirm,
            isShowDeleteConfirmModal,
            id,
            ActionOptions,
            imageSpot.image,
            handleUploadImage,
            handleAddStageAction,
            handleShowDeleteSpotModal,
            handleChangeSelect,
            handleChooseYesOrNoRequired,
            handleChangeArray,
            handleDeleteAction,
            onDeleteStage,
        ],
    );
});

export default StageRegistDetail;
