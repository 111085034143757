import { defaultPhoto } from 'assets/images';
import { CoordinateModel, StageActionItem } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './StageAction.scss';
import { Icon } from 'components';

type Props = {
    stageActionItem: StageActionItem;
    isShowDeleteIcon?: boolean;
    onGoToDetail: () => void;
    onDelete?: () => void;
};

/**
 * my spot item
 * @returns
 */
const StageAction = (props: Props): JSX.Element => {
    const { stageActionItem, isShowDeleteIcon = false, onGoToDetail, onDelete } = props;
    const [location, setLocation] = useState<CoordinateModel>();
    const comment = useMemo(() => {
        return stageActionItem?.action_comment?.replaceAll('\\n', '\n');
    }, [stageActionItem]);

    /**
     * handle get location
     */
    const handleGetLocation = useCallback(async (latitude, longitude) => {
        if (latitude && longitude) {
            // const res = await handleGetPlace(latitude, longitude);
            setLocation({ coordinates: { lat: latitude, lng: longitude }, description: '' });
        } else {
            setLocation({ coordinates: { lat: latitude, lng: longitude }, description: '' });
        }
    }, []);

    // const handleGoToUserDetailPage = useCallback(
    //    (id: string) => (): void => {
    //        navigate(replacePathParams(PATH.USER.DETAIL, { id: id }));
    //    },
    //    [navigate],
    // );

    useEffect(() => {
        handleGetLocation(stageActionItem?.action_latitude, stageActionItem?.action_longitude);
    }, [stageActionItem?.action_latitude, stageActionItem?.action_longitude]);

    return useMemo(
        () =>
            stageActionItem && (
                <div className="stage-action-item">
                    {isShowDeleteIcon && (
                        <div className="close-btn" onClick={onDelete}>
                            <Icon.Close />
                        </div>
                    )}
                    <div className="d-flex gap-2" onClick={onGoToDetail}>
                        <div className="col-3 col-md-3">
                            <div className="text-center">
                                <LazyLoadImage
                                    className="image-stage-action"
                                    src={stageActionItem.action_photo_url || defaultPhoto}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="mission-name">{stageActionItem.action_title}</div>
                            <div className="mission-detail">{comment}</div>
                        </div>
                    </div>
                </div>
            ),
        [stageActionItem, onGoToDetail, isShowDeleteIcon, onDelete, comment],
    );
};

export default StageAction;
