import { useGoogleMap } from '@react-google-maps/api';
import { Icon } from 'components';
import React, { useEffect, useRef, useState } from 'react';
import './MapControl.scss';

interface MapControlProps {
    position: keyof typeof google.maps.ControlPosition;
    className?: string;
    listSpot?: boolean;
    onPanToCurrentLocation?: () => void;
}

const MapControl = (props: React.PropsWithChildren<MapControlProps>): JSX.Element => {
    const { className, listSpot = false, onPanToCurrentLocation } = props;
    const [value, setValue] = useState<any>(0);
    const map = useGoogleMap();
    const ref = useRef<any>();

    const handleZoomUpdate = (zoomChange = 1): void => {
        const nextZoom = value + zoomChange;
        if (nextZoom < 0) {
            return;
        }
        map?.setZoom(nextZoom);
    };
    const handleFullScreen = (): void => {
        let isFullScreen = false;
        if (!isFullScreen) {
            const mapDiv = map?.getDiv();
            const mapClass = mapDiv?.classList;
            mapClass?.add('map-fullscreen');
            mapDiv?.requestFullscreen();
        }
        document.exitFullscreen();
    };
    useEffect(() => {
        if (map && ref) {
            map.controls[window.google.maps.ControlPosition[props.position]].push(ref.current);
        }
    }, [map, ref]);

    useEffect(() => {
        if (!map) {
            return;
        }
        // setValue(map?.getZoom());
        setValue(17);

        const listener = map.addListener('zoom_changed', () => {
            // setValue(map?.getZoom());
            setValue(17);
        });
        return (): void => google.maps.event.removeListener(listener);
    }, [map, google]);

    return (
        <div className={className} ref={ref}>
            <div></div>
            {listSpot ? (
                <div className="current-location-btn">
                    <div className="current-location" onClick={onPanToCurrentLocation}>
                        <Icon.SelfNavigate />
                    </div>
                    <div>現在地</div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};
export default MapControl;
