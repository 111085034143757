import { FrameItem, Icon } from 'components';
import { SpotListItem } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import './SpotDetailRemark.scss';
interface SpotDetailDescribeProps {
    data: SpotListItem;
}

const OFFSET_SCROLL_HEIGHT = 30;

const SpotDetailRemark = ({ data }: SpotDetailDescribeProps): JSX.Element => {
    const [isShowExplanation, setIsShowExplanation] = useState(true);

    const linkifyText = text => {
        const urlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        const parts = text.split(urlRegex);

        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            } else {
                return part.split('\n').map(t => {
                    return (
                        <span key={t}>
                            {t}
                            <br />
                        </span>
                    );
                });
            }
        });
    };

    const explanation = useMemo(() => {
        const explain =
            data?.explanation && data?.explanation.trim() !== '' ? data?.explanation?.replaceAll('\\n', '\n') : '';

        return linkifyText(explain);
    }, [data?.explanation]);

    const handleScroll = useCallback(
        (event: any): void => {
            if ((data?.can_retry || (!data?.can_retry && data?.spot_clear_status === 0)) && data?.is_gps_required) {
                if (window.scrollY >= OFFSET_SCROLL_HEIGHT && isShowExplanation) {
                    setIsShowExplanation(false);
                }
            }
        },
        [data?.can_retry, data?.is_gps_required, data?.spot_clear_status, isShowExplanation],
    );

    const handleToggleExplanation = useCallback(() => {
        setIsShowExplanation(!isShowExplanation);
    }, [isShowExplanation]);

    useEffect(() => {
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll, { passive: true });
        return (): void => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return data?.spot_clear_status > 0 && data?.is_explanation_required ? (
        <div className="mt-4">
            <FrameItem
                children={
                    <div className="spotdetail-remark d-flex flex-column align-items-center">
                        <div className="p-4 pt-0 w-100">
                            <div className="explanation" onClick={handleToggleExplanation}>
                                <div>解説</div>
                                <Icon.SeeMoreDown />
                            </div>
                            <div className={`desc ${!isShowExplanation ? 'hide-explanation' : 'show-explanation'}`}>
                                {explanation}
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    ) : (
        <></>
    );
};

export default SpotDetailRemark;
