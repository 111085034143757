import { FrameItem, Icon, MapCustom } from 'components';
import { CoordinateModel, SpotListItem } from 'models';
import { useEffect, useState } from 'react';
import { handleGetPlace } from 'utils';
import './SpotDetailMap.scss';

type SpotDetailMapProps = {
    data: SpotListItem;
};

const SpotDetailMap = ({ data }: SpotDetailMapProps): JSX.Element => {
    const [location, setLocation] = useState<CoordinateModel>({ coordinates: { lat: 0, lng: 0 }, description: '' });

    const handleGetLocation = async (latitude, longitude): Promise<void> => {
        if (latitude && longitude) {
            const res: any = await handleGetPlace(latitude, longitude, false);

            setLocation({ coordinates: { lat: latitude, lng: longitude }, description: res.substring(3) });
        } else {
            setLocation({ coordinates: { lat: latitude, lng: longitude }, description: '' });
        }
    };

    useEffect(() => {
        handleGetLocation(data?.latitude, data?.longitude);
    }, [data?.latitude, data?.longitude]);

    return (
        <>
            <div className="location-title">位置情報</div>
            {/* <div className="location-des">{location?.description}</div> */}
            <FrameItem
                children={
                    <div className="spotdetail-location">
                        <div className="map">
                            <MapCustom data={data ? [data] : []} detailSpot={true} />
                        </div>
                    </div>
                }
            />
            <div className="map-link">
                <a
                    href={`https://maps.google.com/?q=${location.coordinates.lat},${location.coordinates.lng}`}
                    target="_blank"
                    rel="noreferrer">
                    Google Mapで見る
                </a>
            </div>
        </>
    );
};

export default SpotDetailMap;
