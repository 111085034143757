import { useMemo } from 'react';
import { QrReader } from 'react-qr-reader';
import './QRCodeReaderForAction.scss';

type Props = {
    onScanQr?: (result: any) => void;
};

const QRCodeReaderForAction = (props: Props): JSX.Element => {
    const { onScanQr } = props;
    return useMemo(
        () => (
            <div className="QR-code-reader-container">
                <QrReader
                    onResult={(result: any): void => {
                        if (!!result) {
                            let params = new URLSearchParams(result?.text);
                            const spotClearKey = params.get('action_clear_key') || '';
                            onScanQr?.(spotClearKey);
                        }
                    }}
                    constraints={{ facingMode: 'environment' }}
                />
            </div>
        ),
        [onScanQr],
    );
};

export default QRCodeReaderForAction;
