import AR from 'components/AR';
import { SpotListItem } from 'models';
import React from 'react';
import './UploadFile.scss';
import Input from 'components/Input';
import { SPOT_CLEAR_TYPE } from 'uniforms';

interface UploadFileProps {
    icon?: React.ReactNode;
    titleIcon?: React.ReactNode;
    title?: string;
    desc?: string;
    data: SpotListItem;
    isOpenAR?: boolean;
    errorAR?: boolean;
    onChange?: (e: any) => void;
    handleClickLocation?: (data: any) => void;
    handleTurnOffVideoAndCanvas?: () => void;
}
const UploadFile = ({
    title,
    titleIcon,
    icon,
    desc,
    data,
    isOpenAR,
    onChange,
    handleClickLocation,
    handleTurnOffVideoAndCanvas,
}: UploadFileProps): JSX.Element => {
    return (
        <div className="spotdetail-upload">
            <div className="upload">
                <div className="my-spot-photo">
                    <label htmlFor="file-image-upload" className="custom-file-image-upload" onClick={onChange}>
                        <>
                            {icon}
                            <div className="mt-2">{desc}</div>
                        </>
                    </label>
                    {data.spot_clear_way === SPOT_CLEAR_TYPE.IMAGE.value && (
                        <Input
                            type="file"
                            id="file-image-upload"
                            accept="image/gif,image/jpeg,image/png,.HEIC"
                            key="upload-image"
                            name="image-upload-btn"
                            className="d-none"
                            onChange={onChange}
                        />
                    )}
                    {isOpenAR && (
                        <AR
                            data={data}
                            handleClickLocation={handleClickLocation}
                            handleTurnOffVideoAndCanvas={handleTurnOffVideoAndCanvas}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default UploadFile;
