import { ReactNode, useMemo } from 'react';
import './DetailViewLayout.scss';

/**
 * props model
 */
type Props = {
    children: ReactNode;
    className?: string;
    style?: any;
};

/**
 * Detail view layout
 * @returns
 */
const DetailViewLayout = ({ children, className, style }: Props): JSX.Element => {
    return useMemo(
        () => (
            <div className={`detail-view-container ${className ?? ''}`} style={style}>
                <div className="detail-view-rectangle"></div>
                <div className="detail-view-item">{children}</div>
            </div>
        ),
        [children, className, style],
    );
};

export default DetailViewLayout;
