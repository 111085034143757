import { Armoria, AvatarFrame, Icon, Medal } from 'components';
import { useUser } from 'hooks';
import { UserInfoBoxProps } from 'models';
import { useEffect, useMemo, useState } from 'react';
import './UserInfoBox.scss';

interface Props {
    userInfo: UserInfoBoxProps;
}

const UserInfoBox = ({ userInfo }: Props): JSX.Element => {
    const sizeAvatarFrame = useMemo(
        () => ({
            layer1: 48,
            layer2: 46,
            avatar: 38.5,
        }),
        [],
    );
    const { getUserStatus } = useUser();
    const [currentTitle, setCurrentTitle] = useState('');

    useEffect(() => {
        getUserStatus({
            target_cognito_user_id: userInfo.cognito_user_id,
            successCallback(data) {
                const listTitle = data.result_records.user_status_title_list[0].user_title_list;
                const title = listTitle?.find(title => title.is_current_title);
                title && setCurrentTitle(title.title_display_name);
            },
            failureCallback(data) {},
        });
    }, []);

    const comment = useMemo(() => {
        return userInfo?.user_comment_content?.replaceAll('\\n', `\n`);
    }, [userInfo]);

    return useMemo(
        () =>
            userInfo && (
                <div className="user-info-box">
                    <div className="user-infor-content-wrapper">
                        <div className="user-infor-content">
                            <div className="achievements d-flex justify-content-between align-items-center">
                                <div className="achievements-left d-flex align-items-center gap-2">
                                    {userInfo.permission_id !== 2 ? (
                                        <div
                                            className={
                                                'clear-status ' + (userInfo.user_clear_status ? 'cleared' : 'not-clear')
                                            }>
                                            {userInfo.user_clear_status ? 'クリア済' : '未クリア'}
                                        </div>
                                    ) : null}
                                    {currentTitle !== '' ? (
                                        <div>
                                            <span className="armoria">
                                                <Armoria armoria_level={3} size="24" />
                                            </span>
                                            <span className="armoria-text">{currentTitle}</span>
                                        </div>
                                    ) : null}
                                </div>
                                {userInfo.user_ranking !== undefined && userInfo.user_ranking !== 0 ? (
                                    <div className="achievements-right d-flex gap-2">
                                        <div className="medal">
                                            <Medal user_ranking={userInfo.user_ranking} width={22.5} height={24} />
                                        </div>
                                        <span className="ranking-text">{userInfo.user_ranking}位</span>
                                    </div>
                                ) : null}
                            </div>
                            <div className="info-detail mt-2">
                                <div className="d-flex gap-2">
                                    <AvatarFrame size={sizeAvatarFrame} avatar={userInfo?.avatar_url} />
                                    <div className="d-flex flex-column justify-content-around">
                                        {userInfo.permission_id !== 2 ? (
                                            <div className="name">
                                                {userInfo.user_clear_status === 1 ? userInfo.user_name : '？？？？？'}
                                            </div>
                                        ) : null}
                                        <div className="nick-name">{userInfo.user_display_name}</div>
                                    </div>
                                </div>
                                <div className="bio mt-2">{comment}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                {userInfo.spot_category_display_name ? (
                                    <div className="category">{userInfo.spot_category_display_name}</div>
                                ) : (
                                    <div></div>
                                )}
                                <div className="statistical d-flex justify-content-center align-items-center gap-2">
                                    <div className="location-cleared d-flex gap-1 align-items-center">
                                        <Icon.Location width="20" height="20" />
                                        <span>{userInfo.spot_post_count}</span>
                                    </div>
                                    <div className="point-count d-flex gap-1">
                                        <Icon.PointCount width="20" height="20" />
                                        <span>{userInfo.point || 0}</span>
                                    </div>
                                    <div className="like-count d-flex gap-1">
                                        <Icon.LikeCount width="20" height="20" />
                                        <span>{userInfo.total_spot_like_count}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        [currentTitle, sizeAvatarFrame, userInfo, comment],
    );
};

export default UserInfoBox;
