/**
 * path URL
 */
const PATH = {
    HOME: '/',
    START: '/start',
    EVENT: '/event',
    NOT_FOUND: '/*',
    MY_SPOT: {
        LIST: '/myspot/list',
        REGISTRATION: '/myspot/registration',
        DETAIL: '/myspot/detail/:id',
    },
    SPOT: {
        LIST: '/spot/list',
        DETAIL: '/spot/detail/:id',
        STORY: '/spot/story/:id',
    },
    STAGE: {
        STAGE_ACTION_LIST: '/stage',
        STAGE_ACTION_DETAIL: '/stage/:stage_id/action/:action_id',
        STAGE_START: '/stage/start',
        REGISTRATION: {
            STAGE_LIST: '/stage/registration/list',
            STAGE_REGISTRATION: '/stage/registration',
            STAGE_DETAIL: '/stage/registration/:id',
        },
    },
    USER: {
        LIST: '/user/list',
        DETAIL: '/user/detail/:id',
        DETAIL_2: '/user/detail',
        DETAIL_3: '/user/detail/',
        REGISTER: '/user/register',
        RANK: '/user/rank',
        POINTS_EXCHANGE: '/user/point',
    },
    TERMS: '/terms',
    BOTTLE: {
        DETAIL: '/bottle/detail',
        BORROW: '/bottle/borrow',
        USAGE: '/bottle/usage',
    },
    HOME_1: '/home',
    PAYMENT: '/payment',
};

export default PATH;
