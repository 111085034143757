import { Icon, Input, LocationInfo, Modal, Select, TitleField } from 'components';
import { Environments } from 'configs';
import { Formik, FormikProps } from 'formik';
import { CoordinateModel, SelectOptionItem, StageActionItem } from 'models';
import { QRCodeCanvas } from 'qrcode.react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { LOCATION_DEFAULT, MESSAGES, SPOT_CLEAR_TYPE, SPOT_CLEAR_TYPE_DISPLAY } from 'uniforms';
import { getExtensionImage, handleGetPlace, handleResizeImage, mapAnswerArrayData, showErrorMessage } from 'utils';
import * as Yup from 'yup';
import './StageRegistDetail.scss';

/**
 * Props type
 */
type Props = {
    data?: StageActionItem;
    stageOptions: SelectOptionItem[];
    onClose: () => void;
    onSubmit: (value: StageActionItem) => void;
};

/**
 * stage registration detail component
 * @returns
 */
const StageRegistModal = forwardRef((props: Props, ref): JSX.Element => {
    const { data, onClose, stageOptions, onSubmit } = props;
    const formikRef = useRef<FormikProps<StageActionItem>>(null);
    const [imageSpot, setImageSpot] = useState<{ image: any }>({
        image: '',
    });
    const [currentLocation, setCurrentLocation] = useState<CoordinateModel>(LOCATION_DEFAULT);
    const [isShowLocationInfo, setIsShowLocationInfo] = useState<boolean>(false);
    const [spotClearType, setSpotClearType] = useState<{ label: string; value: number }>({ label: '', value: 0 });

    useImperativeHandle(ref, () => ({
        handleSubmitForm(): void {
            return handleSubmitData(formikRef?.current?.values);
        },
    }));

    const comment = useMemo(() => {
        return data?.action_comment;
    }, [data]);

    const spotClearWayDisplay = useMemo(() => {
        const stageClearDisplay = {
            ...SPOT_CLEAR_TYPE_DISPLAY,
            CANCEL: { label: 'キャンセル', value: 9 },
        };
        const keysToKeep = Object.keys(stageClearDisplay).filter(key => key !== 'COUPON');

        return Object.entries(stageClearDisplay)
            .filter(([key]) => keysToKeep.includes(key))
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
    }, []);

    /**
     * initital value of form
     */
    const INITIAL_VALUES: StageActionItem = useMemo(
        () => ({
            ...data,
            action_title: data?.action_title || '',
            action_comment: (comment || '').replaceAll('\\n', '\n'),
            description: '',
            action_points: data?.action_points || 10,
            action_photo_url: data?.action_photo_url || '',
            action_latitude: data?.action_latitude || 0,
            action_longitude: data?.action_longitude || 0,
            action_radius: data?.action_radius || 0,
            action_clear_way: data?.action_clear_way || 0,
            action_clear_key: data?.action_clear_key || '',
            next_stage_id: data?.next_stage_id || 0,
            next_stage: {
                value: data?.next_stage_id || 0,
                label: stageOptions.find(x => x.value === data?.next_stage_id)?.label || '',
            },
        }),
        [data, comment, stageOptions],
    );

    /**
     * SCHEMA of Form
     */
    const SCHEMA = useMemo(
        () =>
            Yup.object().shape({
                action_title: Yup.string().trim().required(showErrorMessage(MESSAGES.CHECK_REQUIRED, 'タイトル')),
                action_comment: Yup.string()
                    .trim()
                    .required(showErrorMessage(MESSAGES.CHECK_REQUIRED, 'ミッションの説明')),
                action_photo_url: Yup.string().required(showErrorMessage(MESSAGES.CHOOSE_REQUIRED, '写真')),
                action_clear_way: Yup.number().required(
                    showErrorMessage(MESSAGES.CHOOSE_REQUIRED, 'ミッションクリア方法 '),
                ),
                action_clear_key: Yup.string()
                    .trim()
                    .when('action_clear_way', {
                        is: val => [SPOT_CLEAR_TYPE.WORD.value, SPOT_CLEAR_TYPE.CHOICE.value].includes(val),
                        then: Yup.string().required(showErrorMessage(MESSAGES.CHECK_REQUIRED, '答え')),
                    }),
            }),
        [],
    );

    /**
     * handle submit form
     */
    const handleSubmitData = useCallback(
        values => {
            const objAnswer = mapAnswerArrayData(values, true);
            if (
                values.action_clear_way === SPOT_CLEAR_TYPE.CHOICE.value &&
                !objAnswer.action_answer_choice1 &&
                !objAnswer.action_answer_choice2 &&
                !objAnswer.action_answer_choice3 &&
                !objAnswer.action_answer_choice4
            ) {
                formikRef.current?.setFieldError(
                    'action_answer_choice1',
                    showErrorMessage(MESSAGES.CHECK_REQUIRED, '選択肢'),
                );
                return;
            }
            delete values.action_answer_choice1;
            delete values.action_answer_choice2;
            delete values.action_answer_choice3;
            delete values.action_answer_choice4;
            const params = {
                ...values,
                ...objAnswer,
                action_title: values.action_title.trim(),
                action_comment: values.action_comment.trim(),
                action_clear_key: values.action_clear_key ? values.action_clear_key.trim() : '',
            };
            onSubmit(params);
        },
        [onSubmit],
    );

    /**
     * handle submit location
     */
    const handleSubmitLocation = useCallback((location: CoordinateModel) => {
        setCurrentLocation(location);
        formikRef?.current?.setFieldValue('description', location.description);
        formikRef?.current?.setFieldValue('action_latitude', location.coordinates.lat);
        formikRef?.current?.setFieldValue('action_longitude', location.coordinates.lng);

        setIsShowLocationInfo(false);
    }, []);

    const handleCloseModal = useCallback(() => {
        setIsShowLocationInfo(false);
    }, []);

    /**
     * handle choose spot clear type
     */
    const handleChooseSpotClearType = useCallback(
        item => (): void => {
            if (item !== spotClearType.value) {
                setSpotClearType(item);
                formikRef?.current?.setFieldValue('action_clear_way', item.value);
            } else {
                setSpotClearType({ label: '', value: 0 });
                formikRef?.current?.setFieldValue('action_clear_way', 0);
            }
        },
        [spotClearType],
    );

    /**
     * handle choose location info
     */
    const handleChooseLocation = useCallback(() => {
        setIsShowLocationInfo(true);
    }, []);

    /**
     * handle change next stage
     */
    const handleChangeSelect = useCallback((value: any) => {
        formikRef?.current?.setFieldValue('next_stage_id', value?.value);
        formikRef?.current?.setFieldValue('next_stage', value);
    }, []);

    /**
     * Handle upload image
     * @params e
     * @returns void
     */
    const handleUploadImage = useCallback(async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const extension = getExtensionImage(e.target.files[0].name).toLocaleUpperCase();
            const image = await handleResizeImage(file, extension);
            setImageSpot({ image });
            formikRef?.current?.setFieldValue('action_photo_url', image);
        }
    }, []);

    useEffect(() => {
        if (data?.action_photo_url) {
            setImageSpot({ image: data.action_photo_url });
        }

        const getCurrentPlace = async (): Promise<void> => {
            if (data?.action_latitude && data?.action_longitude) {
                const place = await handleGetPlace(data?.action_latitude, data?.action_longitude);
                formikRef?.current?.setFieldValue('description', place || '');
            }
        };

        getCurrentPlace();
    }, [data?.action_latitude, data?.action_longitude, data?.action_photo_url]);

    useEffect(() => {
        if (data && data.action_clear_way) {
            setSpotClearType({
                value: data.action_clear_way,
                label:
                    data.action_clear_way === SPOT_CLEAR_TYPE.AR.value
                        ? SPOT_CLEAR_TYPE.AR.label
                        : data.action_clear_way === SPOT_CLEAR_TYPE.QR.value
                        ? SPOT_CLEAR_TYPE.QR.label
                        : data.action_clear_way === SPOT_CLEAR_TYPE.WORD.value
                        ? SPOT_CLEAR_TYPE.WORD.label
                        : data.action_clear_way === SPOT_CLEAR_TYPE.BUTTON.value
                        ? SPOT_CLEAR_TYPE.BUTTON.label
                        : SPOT_CLEAR_TYPE.CARD.label,
            });
        }
    }, [data?.action_clear_way]);

    return useMemo(
        () => (
            <div className="stage-regist-modal-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={INITIAL_VALUES}
                    enableReinitialize={true}
                    validationSchema={SCHEMA}
                    onSubmit={handleSubmitData}>
                    {({ values, errors, handleSubmit, handleChange, handleBlur, touched }): JSX.Element => (
                        <form className="form-container" onSubmit={handleSubmit}>
                            <div className="form-submit-border">
                                <div className="mb-3">
                                    <div className="stage-photo">
                                        <div>
                                            <label
                                                htmlFor={`file-image-upload-${data?.action_id || 0}`}
                                                className="custom-file-image-upload"
                                                style={{
                                                    border: imageSpot.image && 'none',
                                                    padding: imageSpot.image && 0,
                                                }}>
                                                {imageSpot.image ? (
                                                    <img
                                                        className="image-spot"
                                                        height={160}
                                                        width={160}
                                                        src={imageSpot.image}
                                                        alt="avatar"
                                                    />
                                                ) : (
                                                    <>
                                                        <Icon.Plus />
                                                        <div className="mt-2 text-sm">写真 PHOTO</div>
                                                    </>
                                                )}
                                            </label>
                                            <Input
                                                type="file"
                                                id={`file-image-upload-${data?.action_id || 0}`}
                                                accept="image/gif,image/jpeg,image/png"
                                                key="upload-image"
                                                name="image-upload-btn"
                                                className="d-none"
                                                onChange={handleUploadImage}
                                            />
                                            {!!errors.action_photo_url && !!touched.action_photo_url && (
                                                <div className="text-error">{errors.action_photo_url}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <TitleField title="タイトル" />
                                        <Input
                                            type="text"
                                            name="action_title"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.action_title}
                                            error={!!errors.action_title && !!touched.action_title}
                                            msgError={errors.action_title}
                                        />
                                    </div>

                                    <div className="mb-2">
                                        <TitleField title="説明" />
                                        <div className="input-container">
                                            <textarea
                                                className={`${errors.action_comment ? 'input-error' : ''}`}
                                                name="action_comment"
                                                rows={7}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.action_comment}
                                                style={{ resize: 'none' }}
                                            />
                                            {!!errors.action_comment && !!touched.action_comment && (
                                                <div className="text-error">{errors.action_comment}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <TitleField title="位置情報" />
                                        <div onClick={handleChooseLocation}>
                                            <Input
                                                mapInput={true}
                                                value={(values.description || '')
                                                    .replace(/^日本、/g, '')
                                                    .replace(/^〒[0-9]{3}-[0-9]{4}/g, '')}
                                                name="description"
                                                error={!!errors.description && !!touched.description}
                                                msgError={errors.description}
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <TitleField title="GPS許容半径 [メートル]" />
                                        <Input
                                            type="number"
                                            name="action_radius"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.action_radius}
                                            error={!!errors.action_radius && !!touched.action_radius}
                                            msgError={errors.action_radius}
                                        />
                                    </div>

                                    <div className="mb-2">
                                        <TitleField title="クリア後の獲得ポイント" />
                                        <Input
                                            type="number"
                                            name="action_points"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            min={0}
                                            value={values.action_points}
                                            error={!!errors.action_points && !!touched.action_points}
                                            msgError={errors.action_points}
                                        />
                                    </div>

                                    <div className="mb-2">
                                        <TitleField title="ミッションクリア方法" />

                                        <div className="stage-category-list pb-3">
                                            {Object.keys(spotClearWayDisplay).map(key => {
                                                return (
                                                    <div
                                                        key={spotClearWayDisplay[key].value}
                                                        className={`w-100 text-center ${
                                                            spotClearType.value === spotClearWayDisplay[key].value
                                                                ? 'active-badge'
                                                                : 'inactive-badge'
                                                        }`}
                                                        style={{ maxWidth: 'fit-content' }}
                                                        onClick={handleChooseSpotClearType(spotClearWayDisplay[key])}>
                                                        {spotClearWayDisplay[key].label}
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {spotClearType.value === SPOT_CLEAR_TYPE.QR.value && !!data?.action_id && (
                                            <div className="stage-QR-container">
                                                <div className="stage-QR-code">
                                                    <QRCodeCanvas
                                                        value={`${Environments.lineLink}/stage/${data?.stage_id}/${
                                                            data?.action_id
                                                        }?action_clear_key=${data?.action_clear_key || ''}`}
                                                        size={240}
                                                        level={'M'}
                                                        includeMargin={true}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {values.action_clear_way === 0 && !!touched.action_clear_way && (
                                            <div className="text-error">
                                                {showErrorMessage(MESSAGES.CHOOSE_REQUIRED, 'スポットクリア方法')}
                                            </div>
                                        )}

                                        {(spotClearType.value === SPOT_CLEAR_TYPE.WORD.value ||
                                            spotClearType.value === SPOT_CLEAR_TYPE.CHOICE.value) && (
                                            <div className="mb-2">
                                                <TitleField
                                                    title="答え"
                                                    titleNote={
                                                        spotClearType.value === SPOT_CLEAR_TYPE.CHOICE.value
                                                            ? '選択肢の単語と一致する必要があります。'
                                                            : ''
                                                    }
                                                />
                                                <Input
                                                    type="text"
                                                    name="action_clear_key"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.action_clear_key}
                                                    error={!!errors.action_clear_key && !!touched.action_clear_key}
                                                    msgError={errors.action_clear_key}
                                                />
                                            </div>
                                        )}

                                        {spotClearType.value === SPOT_CLEAR_TYPE.CHOICE.value && (
                                            <div className="mb-2">
                                                <TitleField
                                                    title="選択肢"
                                                    titleNote="記入がない選択肢は非表示になります。"
                                                />
                                                <Input
                                                    type="text"
                                                    name="action_answer_choice1"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.action_answer_choice1}
                                                    error={
                                                        !!errors.action_answer_choice1 &&
                                                        !!touched.action_answer_choice1
                                                    }
                                                    msgError={errors.action_answer_choice1}
                                                />
                                                <Input
                                                    type="text"
                                                    className="mt-1"
                                                    name="action_answer_choice2"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.action_answer_choice2}
                                                    error={
                                                        !!errors.action_answer_choice2 &&
                                                        !!touched.action_answer_choice2
                                                    }
                                                    msgError={errors.action_answer_choice2}
                                                />
                                                <Input
                                                    type="text"
                                                    className="mt-1"
                                                    name="action_answer_choice3"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.action_answer_choice3}
                                                    error={
                                                        !!errors.action_answer_choice3 &&
                                                        !!touched.action_answer_choice3
                                                    }
                                                    msgError={errors.action_answer_choice3}
                                                />
                                                <Input
                                                    type="text"
                                                    className="mt-1"
                                                    name="action_answer_choice4"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.action_answer_choice4}
                                                    error={
                                                        !!errors.action_answer_choice4 &&
                                                        !!touched.action_answer_choice4
                                                    }
                                                    msgError={errors.action_answer_choice4}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-2">
                                        <TitleField title="次のステージ" />
                                        <Select
                                            name="next_stage_id"
                                            options={stageOptions}
                                            onChange={(value): void => handleChangeSelect(value)}
                                            value={values.next_stage}
                                        />
                                    </div>

                                    <div className="d-flex flex-column my-4">
                                        <Button className="green-btn mx-auto mb-3" type="submit">
                                            アクションを追加
                                        </Button>
                                        <Button className="cancel-btn mx-auto" onClick={(): void => onClose()}>
                                            閉じる
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
                <Modal
                    show={isShowLocationInfo}
                    isHide={true}
                    onClose={handleCloseModal}
                    children={<LocationInfo onSubmit={handleSubmitLocation} location={currentLocation} />}
                />
            </div>
        ),
        [
            INITIAL_VALUES,
            SCHEMA,
            handleSubmitData,
            isShowLocationInfo,
            handleCloseModal,
            handleSubmitLocation,
            currentLocation,
            imageSpot.image,
            handleUploadImage,
            handleChooseLocation,
            spotClearWayDisplay,
            spotClearType.value,
            data?.stage_id,
            data?.action_id,
            data?.action_clear_key,
            stageOptions,
            handleChooseSpotClearType,
            handleChangeSelect,
            onClose,
        ],
    );
});

export default StageRegistModal;
