import { Button } from 'react-bootstrap';
import './SuccessModal.scss';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { useCallback, useRef } from 'react';

type Props = {
    firstTitle?: string;
    secondTitle?: string;
    thirdTitle?: string;
    fourTitle?: string;
    textSubmitButton?: string;
    imgContent?: string;
    imgContentControl?: string;
    btnClassName?: string;
    extraBtnClassName?: string;
    textExtraButton?: string;
    extraButton?: boolean;
    onSubmit?: () => void;
    onExtraSubmit?: () => void;
};

const SuccessModal = (props: Props): JSX.Element => {
    const imgRef = useRef();
    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img }: any = imgRef;
        if (img) {
            const value = make3dTransformValue({ x, y, scale });

            img.style.setProperty("transform", value);
        }
    }, []);

    const {
        onSubmit,
        onExtraSubmit,
        extraButton = false,
        textExtraButton = '',
        firstTitle = '',
        secondTitle = '',
        thirdTitle = '',
        fourTitle = '',
        textSubmitButton = '',
        imgContent = '',
        imgContentControl = '',
        btnClassName = 'green-btn',
        extraBtnClassName = 'green-btn mt-3',
    } = props;
    return (
        <div className="success-modal-container p-3">
            {imgContent && <img className="image" src={imgContent} alt=""></img>}
            {imgContentControl && (

                <div className="image-container">
                    <QuickPinchZoom onUpdate={onUpdate}>
                        <img ref={imgRef as any} className="image" src={imgContentControl} alt=""></img>
                    </QuickPinchZoom>
                </div>

            )}
            {firstTitle && <div className="first-title">{firstTitle}</div>}
            {secondTitle && <div className="second-title">{secondTitle}</div>}
            {thirdTitle && <div className="third-title">{thirdTitle}</div>}
            {fourTitle && <div className="four-title">{fourTitle}</div>}
            <div className="d-flex gap-1 justify-content-center flex-column align-items-center">
                <Button className={btnClassName} onClick={onSubmit}>
                    {textSubmitButton}
                </Button>
                {extraButton && (
                    <Button className={extraBtnClassName} onClick={onExtraSubmit}>
                        {textExtraButton}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default SuccessModal;
