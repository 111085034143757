import { Armonia3 } from 'assets/images';
import { Armoria, AvatarFrame, FrameItem } from 'components';
import { UserRelatedInfo } from 'models';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { replacePathParams } from 'utils';
import { PATH } from 'uniforms';
import './UserRelated.scss';
interface Props {
    userInfo: UserRelatedInfo;
}

const UserRelatedBox = ({ userInfo }: Props) => {
    const navigate = useNavigate();
    const sizeAvatarFrameOtherPeople = useMemo(
        () => ({
            layer1: 32,
            layer2: 30,
            avatar: 25,
        }),
        [],
    );

    const handleGoToUserDetailPage = useCallback(
        (id: string) => (): void => {
            navigate(replacePathParams(PATH.USER.DETAIL, { id: id }));
        },
        [navigate],
    );

    return useMemo(
        () => (
            <>
                <div
                    className="other-people-info d-flex gap-2 justify-content-center mb-2"
                    onClick={handleGoToUserDetailPage(userInfo?.cognito_user_id || '0')}>
                    <div className="other-user-avatar">
                        <AvatarFrame size={sizeAvatarFrameOtherPeople} avatar={userInfo.avatar_url} />
                    </div>
                    <div className="other-people-nickname">{userInfo.user_display_name}</div>
                </div>
                <div className="other-people-all-degree d-flex flex-column rounded">
                    {userInfo?.user_title_list &&
                        userInfo?.user_title_list.length > 0 &&
                        userInfo?.user_title_list.map((title, index) => (
                            <div className="mt-2" key={index}>
                                <FrameItem>
                                    <div className="armoria_box p-2 rounded">
                                        <div className="d-flex gap-1 armoria_text">
                                            <img src={Armonia3} alt="" />
                                            {title.title_display_name}
                                        </div>
                                        <span className="armoria-detail mt-1 d-flex justify-content-center align-items-center">
                                            {title.title_content}
                                        </span>
                                    </div>
                                </FrameItem>
                            </div>
                        ))}
                </div>
            </>
        ),
        [userInfo],
    );
};

export default UserRelatedBox;
