import { fetcherApi } from 'api/fetcher';
import {
    MySpotDetailParams,
    MySpotDetailRegistParams,
    MySpotDetailResponse,
    MySpotDetailUpdateParams,
    MySpotListParams,
    MySpotListResponse,
} from 'models';
import { APILIST } from 'uniforms';
import { HandleCallbackApi } from 'utils';

export async function getMySpotListService(params: MySpotListParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<MySpotListParams, MySpotListResponse>(body, APILIST.MYSPOT.LIST, 'POST');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getMySpotDetailService(params: MySpotDetailParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<MySpotDetailParams, MySpotDetailResponse>(body, APILIST.MYSPOT.DETAIL, 'GET');

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postMySpotDetailService(params: MySpotDetailRegistParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<MySpotDetailRegistParams, MySpotDetailResponse>(
        body,
        APILIST.MYSPOT.DETAIL,
        'POST',
    );

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function putMySpotDetailService(params: MySpotDetailUpdateParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<MySpotDetailUpdateParams, MySpotDetailResponse>(
        body,
        APILIST.MYSPOT.DETAIL,
        'PUT',
    );

    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
