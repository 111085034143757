/* eslint-disable */
import { Icon } from 'components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { hideLoading } from 'utils';
import './AR.css';
const AR = ({ data, handleClickLocation, handleTurnOffVideoAndCanvas }) => {
    const [hover, setHover] = useState({ backIcon: false, saveIcon: false });
    const objectRef = useRef();
    const meterRef = useRef();
    const cameraRef = useRef();
    const [myData, setMyDataData] = useState({
        maxDistance: 15,
        position: '0 2 -5',
        rotation: '0 0 0',
        scale: '0.6 0.6 0.6',
    });
    const handleHoverIcon = useCallback(
        icon => () => {
            setHover(prev => {
                prev[icon] = true;
                return { ...prev };
            });
        },
        [],
    );
    const handleLeaveIcon = useCallback(
        icon => () => {
            setHover(prev => {
                prev[icon] = false;
                return { ...prev };
            });
        },
        [],
    );
    const handleGoBack = () => {
        handleTurnOffVideoAndCanvas();
    };
    useEffect(() => {
        const camera = cameraRef?.current;
        const object = objectRef?.current;
        const meter = meterRef?.current;
        const handleDisplayDistance = event => {
            const canvas = document.querySelector('.a-canvas');
            if (meter !== undefined) {
                meter.textContent = `あと ${Math.floor(event.detail.distance).toLocaleString()} m`;
            }
            if (canvas !== undefined) {
                canvas.classList.add('canvas-display');
            }
            camera.setAttribute('gps-camera','gpsMinDistance: 5');
        };
        const handleArjsVideoLoaded = () => {
            if (meter !== undefined) {
                meter.classList.add('d-block');
                meter.textContent = 'ARを探そう!!!';
                hideLoading();
            }
        };

        const handleCameraUpdatePosition = () => {
            //alert("handleCameraUpdatePosition");
            if (meter !== undefined) {
                meter.classList.add('d-block');
                meter.textContent = 'ARを探そう!';
                hideLoading();
            }
            camera.setAttribute('gps-camera','gpsMinDistance: 5');
        };

        // const handleEntityAddedOrLoaded = event => {
        //     const canvas = document.querySelector('.a-canvas');
        //     if (meter !== undefined) {
        //         meter.classList.add('d-block');
        //         meter.textContent = 'ARを探そう!!!';
        //         hideLoading();
        //     }
        //     if (canvas !== undefined) {
        //         canvas.classList.add('canvas-display');
        //     }
        // };

        if (object !== undefined) {
            window.addEventListener('arjs-video-loaded', handleArjsVideoLoaded);
            object.addEventListener('gps-entity-place-update-position', handleDisplayDistance);
            camera.addEventListener('gps-camera-update-positon', handleCameraUpdatePosition)
            // object.addEventListener('gps-entity-place-added', handleEntityAddedOrLoaded);
            // object.addEventListener('gps-entity-place-loaded', handleEntityAddedOrLoaded)
        }
        return () => {
            window.removeEventListener('arjs-video-loaded', handleArjsVideoLoaded);
            object.removeEventListener('gps-entity-place-update-position', handleDisplayDistance);
            camera.removeEventListener('gps-camera-update-positon', handleCameraUpdatePosition)
            // object.removeEventListener('gps-entity-place-added', handleEntityAddedOrLoaded)
            // object.removeEventListener('gps-entity-place-loaded', handleEntityAddedOrLoaded)
        };
    }, []);
    useEffect(() => {
        return () => {
            handleTurnOffVideoAndCanvas();
        };
    }, []);
    return (
        <div className="AR">
            <div
                className="AR-back-icon cursor-pointer"
                onClick={handleGoBack}
                onMouseOver={handleHoverIcon('backIcon')}
                onMouseLeave={handleLeaveIcon('backIcon')}>
                {!hover.backIcon ? <Icon.BackIcon /> : <Icon.PressedBackIcon />}
            </div>
            <div className="AR-meter" ref={meterRef}></div>
            <a-scene
                id="scene"
                vr-mode-ui="enabled: false"
                gps-camera-debug
                embedded
                arjs="sourceType: webcam; debugUIEnabled: false;"
                device-orientation-permission-ui="enabled: true">
                <a-entity
                    class="screen"
                    htmlembed="ppu:60; styleSheetId: marker-style;"
                    position={myData.position}
                    scale={myData.scale}
                    look-at="[gps-camera]"
                    ref={objectRef}
                    gps-entity-place={`latitude: ${data?.latitude}; longitude: ${data?.longitude};`}
                    animation="property: position; easing: easeInOutQuad; dir: alternate; dur: 1000; to: 0 2 -5; loop: true">
                    <div
                        className="AR-location-container"
                        onClick={() => {
                            handleClickLocation(data?.spot_clear_key);
                        }}>
                            <Icon.LocationBanner width="100%" height="100%" />
                        <div className="AR-location-wrapper">
                            <div className="AR-marker-icon">
                                <Icon.MarkerIcon />
                            </div>
                            <div className="AR-marker-location">{data?.spot_title}</div>
                        </div>
                    </div>
                </a-entity>
                <a-entity id="cameraRig">
                    <a-camera
                        fov="40;"
                        id="camera"
                        ref={cameraRef}
                        gps-camera={`maxDistance: ${myData.maxDistance}; gpsMinDistance:1;`}
                        rotation-reader></a-camera>
                    <a-entity id="mouseCursor" cursor="rayOrigin: mouse" raycaster="objects: .screen"></a-entity>
                </a-entity>
            </a-scene>
        </div>
    );
};

export default AR;
