import { createSlice } from '@reduxjs/toolkit';
import { CommonModel } from 'models';
import { ALERT_STATUS } from 'uniforms';

/**
 * initial state
 */
const initialState: CommonModel = {
    loading: 0,
    alert: {
        isShow: false,
        message: '',
        status: ALERT_STATUS.WARNING,
    },
};

/**
 * common reducer
 */
const commonReducer = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setLoading: state => {
            state.loading++;
        },
        finishLoading: state => {
            state.loading--;
        },
        clearLoading: state => {
            state.loading = 0;
        },
        setAlert: (state, action) => {
            state.alert = {
                ...state,
                message: action.payload.message,
                isShow: action.payload.isShow,
                status: action.payload.status,
            };
        },
    },
});
export const { setLoading, finishLoading, clearLoading, setAlert } = commonReducer.actions;
export default commonReducer.reducer;
