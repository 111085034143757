const config = {
    // BASE_URL_API: 'https://zwpf3wivp7.execute-api.ap-northeast-1.amazonaws.com/dev-lae-api/',
    // dev
    // BASE_URL_API: 'https://dmha9f5deyo82.cloudfront.net/dev-fruitpark-lae-api/',
    // BASE_URL_WEB: 'https://dmha9f5deyo82.cloudfront.net',
    // LINE_LINK: 'https://liff.line.me/2006308013-ZBRXR8DB',
    // LIFF_ID: '2006308013-ZBRXR8DB',
    // ADD_FRIEND_LINK: 'https://lin.ee/2lIv6MC',
    // prod
    BASE_URL_API: 'https://lae-f.com/prod-fruitpark-lae-api/',
    BASE_URL_WEB: 'https://lae-f.com',
    // BASE_URL_API: 'http://localhost:3001/',
    LINE_LINK: 'https://liff.line.me/2006429209-p64YNzRO/',
    LIFF_ID: '2006429209-p64YNzRO',
    ADD_FRIEND_LINK: 'https://lin.ee/rkvvbyv',
    GOOGLE_MAP_API_KEY: 'AIzaSyCyJ0oIU276EOSfDTMCfVpm2oz_e_oh76Y',
    LINE_URL: 'https://line.me/R/',
    ENV_ID: 'fruitpark',
};

const Environments = {
    baseUrl: config.BASE_URL_API,
    baseUrlWeb: config.BASE_URL_WEB,
    googleMapApiKey: config.GOOGLE_MAP_API_KEY,
    lineLink: config.LINE_LINK,
    lineUrl: config.LINE_URL,
    liffId: config.LIFF_ID,
    addFriendLink: config.ADD_FRIEND_LINK,
    envId: config.ENV_ID,
};

export default Environments;
