import { GoogleMap, OverlayView, OverlayViewF } from '@react-google-maps/api';
import { Icon, MapControl } from 'components';
import { CategoryListItem, CoordinateModel, LocationProps, SpotListItem } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LOCATION_DEFAULT } from 'uniforms';
import { handleGetPlace } from 'utils';
import './MapCustom.scss';

type Props = {
    data: LocationProps[];
};

const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
};

const defaultMapOptions = {
    disableDefaultUI: true,
};

const MapCustom = (props: Props): JSX.Element => {
    const { data } = props;

    const [currentCoordinate, setCurrentCoordinate] = useState<CoordinateModel>(LOCATION_DEFAULT);
    const [map, setMap] = useState<any>(null);

    /**
     * handle load map
     */
    const handleLoadMap = (map): void => {
        setMap(map);
    };

    /**
     * handle on unmount map
     */
    const handleUnmountMap = (): void => {
        setMap(null);
    };

    const handleGoToCurrentLocation = useCallback(async () => {
        if (navigator.geolocation) {
            navigator?.geolocation.getCurrentPosition(async ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                let des = await handleGetPlace(lat, lng);
                map?.panTo({ lat: lat, lng: lng });
                setCurrentCoordinate({ coordinates: pos, description: des });
            });
        }
    }, [map]);

    useEffect(() => {
        if (data.length > 0) {
            setCurrentCoordinate({ coordinates: { lat: 24.348116, lng: 124.013287 } });
        }
    }, [data]);

    return useMemo(
        () => (
            <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={10}
                center={currentCoordinate.coordinates}
                options={defaultMapOptions}
                onLoad={handleLoadMap}
                onUnmount={handleUnmountMap}>
                <div className="control">
                    {data.map((item, index) => (
                        <OverlayViewF
                            key={index}
                            getPixelPositionOffset={(width, height): { x: number; y: number } => ({
                                x: -(width / 2),
                                y: -height / 2,
                            })}
                            position={{
                                lat: parseFloat(String(item.latitude)),
                                lng: parseFloat(String(item.longitude)),
                            }}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                            <div className="custom-marker">
                                <Icon.CustomMarker width={40} height={40} color="#64E6E8" />
                                <div className="img-marker">{Number(index + 1)}</div>
                            </div>
                        </OverlayViewF>
                    ))}
                </div>
            </GoogleMap>
        ),
        [currentCoordinate.coordinates, handleGoToCurrentLocation],
    );
};
export default MapCustom;
