import { Frame } from 'assets/images';
import { useMemo } from 'react';
import './AvatarFrame.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AvatarPlaceholder from '../../assets/images/avatar-placeholder.png';

type Props = {
    className?: string;
    avatar?: string;
    medium?: boolean;
    small?: boolean;
    size?: { layer1: number; layer2: number; avatar: number };
};

/**
 * avatar frame
 * @returns
 */
const AvatarFrame = (props: Props): JSX.Element => {
    const {
        className = '',
        avatar = '',
        small = false,
        medium = false,
        size = { layer1: 40, layer2: 40, avatar: 32 },
    } = props;
    return useMemo(
        () => (
            // <div
            //     className={`avatar-container col-3 ${className} ${small ? 'small' : ''} ${medium ? 'spot-list-avatar' : ''}`}
            //     style={{ width: size.containerSize, height: size.containerSize }}>
            //     <img
            //         className="avatar-background"
            //         src={Frame}
            //         style={{ width: size.frameSize, height: size.frameSize }}
            //         alt=''></img>
            //     <img
            //         className="avatar"
            //         src={avatar || AvatarPlaceholder}
            //         width={size.avatarSize}
            //         height={size.avatarSize}
            //         alt=''></img>
            //     <div className="top-left"></div>
            //     <div className="top-right"></div>
            //     <div className="bottom-left"></div>
            //     <div className="bottom-right"></div>
            // </div>
            <div className="background-layer-1" style={{ width: size.layer1, height: size.layer1 }}>
                <div className="background-layer-2" style={{ width: size.layer2, height: size.layer2 }}></div>
                <div className="avatar-wrap">
                    <LazyLoadImage
                        className="avatar"
                        src={avatar || AvatarPlaceholder}
                        width={size.avatar}
                        height={size.avatar}
                        alt=""
                    />
                    <div className="top-left"></div>
                    <div className="top-right"></div>
                    <div className="bottom-left"></div>
                    <div className="bottom-right"></div>
                </div>
            </div>
        ),
        [avatar, size.layer1, size.layer2, size.avatar],
    );
};

export default AvatarFrame;
