import { GoogleMap, Marker } from '@react-google-maps/api';
import { FrameItem, Icon, SearchMapSuggestion } from 'components';
import { CoordinateModel } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { LOCATION_DEFAULT } from 'uniforms';
import { handleGetPlace } from 'utils';
import './LocationInfo.scss';

const containerStyle = {
    width: '100%',
    height: '100vh',
};

type Props = {
    onSubmit: (location: CoordinateModel, step: number, seeMore?: boolean) => void;
    location: CoordinateModel;
};

/**
 * location information page
 * @returns
 */
const LocationInfo = (props: Props): JSX.Element => {
    const { onSubmit, location } = props;
    const [currentCoordinate, setCurrentCoordinate] = useState<CoordinateModel>(LOCATION_DEFAULT);
    const [map, setMap] = useState<any>(null);

    const defaultMapOptions = useMemo(
        () => ({
            disableDefaultUI: true,
        }),
        [],
    );

    /**
     * handle load map
     */
    const handleLoadMap = useCallback(map => {
        setMap(map);
    }, []);

    /**
     * handle on unmount map
     */
    const onUnmount = useCallback((map): void => {
        setMap(null);
    }, []);

    /**
     * handle select location from select box
     */
    const handleSelectLocation = useCallback((coordinate: CoordinateModel) => {
        setCurrentCoordinate(coordinate);
    }, []);

    /**
     * handle update location
     */
    const handleMapUpdate = useCallback(
        async e => {
            let des = await handleGetPlace(e.latLng.lat(), e.latLng.lng());
            map?.panTo({ lat: e.latLng.lat(), lng: e.latLng.lng() });
            setTimeout(() => {
                setCurrentCoordinate({ coordinates: { lat: e.latLng.lat(), lng: e.latLng.lng() }, description: des });
            }, 400);
        },
        [map],
    );

    /**
     * handle submit location
     */
    const handleSubmitLocation = useCallback(() => {
        onSubmit && onSubmit(currentCoordinate, 1, true);
    }, [currentCoordinate, onSubmit]);

    /**
     * handle get current location
     */
    const handleGetCurrentLocation = useCallback(async () => {
        let des = await handleGetPlace(LOCATION_DEFAULT.coordinates.lat, LOCATION_DEFAULT.coordinates.lng);
        setCurrentCoordinate({ coordinates: LOCATION_DEFAULT.coordinates, description: des });
    }, []);

    useEffect(() => {
        if (location && location?.description && location.coordinates !== LOCATION_DEFAULT.coordinates) {
            setCurrentCoordinate(location);
        } else {
            handleGetCurrentLocation();
        }
    }, [location]);

    return useMemo(
        () => (
            <div className="google-map-container">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    zoom={currentCoordinate?.zoom || 16}
                    center={currentCoordinate.coordinates}
                    onUnmount={onUnmount}
                    options={defaultMapOptions}
                    onLoad={handleLoadMap}
                    onClick={handleMapUpdate}>
                    <Marker
                        position={{
                            lat: currentCoordinate.coordinates.lat,
                            lng: currentCoordinate.coordinates.lng,
                        }}
                        draggable
                        onDragEnd={handleMapUpdate}
                        animation={window.google.maps.Animation.DROP}
                    />
                </GoogleMap>

                <div className="location-input-container">
                    <div className="location-input">
                        <SearchMapSuggestion
                            onSelectLocation={handleSelectLocation}
                            description={currentCoordinate?.description}
                        />
                    </div>
                </div>

                {currentCoordinate?.description && (
                    <div className="footer-map">
                        <div className="footer-border">
                            <div className="footer-background">
                                <div className="description-location">
                                    <div>
                                        <Icon.Coordinate />
                                    </div>
                                    <div className="description">{currentCoordinate?.description}</div>
                                </div>
                            </div>
                        </div>
                        <Button className="green-btn choose-map-btn" onClick={handleSubmitLocation}>
                            決定
                        </Button>
                    </div>
                )}
            </div>
        ),
        [
            currentCoordinate.coordinates,
            currentCoordinate?.description,
            currentCoordinate?.zoom,
            defaultMapOptions,
            handleLoadMap,
            handleMapUpdate,
            handleSelectLocation,
            handleSubmitLocation,
            onUnmount,
        ],
    );
};

export default LocationInfo;
