import { useMemo } from 'react';
import './FrameHighlightTitle.scss';

/**
 * avatar frame
 * @returns
 */
const FrameHighlightTitle = ({children}): JSX.Element => {
    return useMemo(
        () => (
            <div className="frame-highlight-title-container">
                <div className="frame-highlight-title-border">
                    <div className="frame-highlight-title-item">
                        {children}

                        <div className="top-left"></div>
                        <div className="top-right"></div>
                        <div className="bottom-left"></div>
                        <div className="bottom-right"></div>
                    </div>
                </div>
            </div>
        ),
        [children],
    );
};

export default FrameHighlightTitle;
